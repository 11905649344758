import axios from "axios";

export function changeSection(payload) {
  return { type: "SECTION_CHANGE", payload };
}

export function changeFlashpost(payload) {
  return { type: "FLASHPOST_CHANGE", payload };
}

export function changeFirstDoc(payload) {
  return { type: "FIRSTDOC_CHANGE", payload };
}

export function isSiteLoaded(payload) {
  return { type: "IS_SITE_LOADED", payload };
}

export function changeRentingPageCategory(payload) {
  return {
    type: "SELECTED_RENTING_CATEGORY",
    payload: payload,
  };
}

export const fetchItemsByCategory = (category) => async (
  dispatch,
  getState
) => {
  let response;
  if (category === "0") {
    response = await axios.get(`/items`);
    dispatch({ type: "FETCH_ITEMS", payload: response.data });
  } else {
    response = await axios.get(`/items/search/category/${category}`);
    dispatch({ type: "FETCH_ITEMS", payload: response.data });
  }
};

export const fetchItemsByKeyword = (keyword) => async (dispatch, getState) => {
  let response = await axios.get(`/items/search/${keyword}`);
  dispatch({ type: "FETCH_ITEMS", payload: response.data });
};
