import React from "react";
import axios from "axios";
import { Mobile, LargeDevice } from "../components/ReactResponsiveComponents";
import ContactUs from "./ContactUs";
import Fade from 'react-reveal/Fade';

import {
  BlockCentered,
  SubBlockVideo,SubBlock,
  SubBlockImage,
  SubBlockText,
  DoubleBlockImage,
  DoubleBlockImageBold,
  BlockImage2,
  Block,
  Title,
  SubtitleDateContainer,
  WideOpenImage,
  Subtitle,
  Date,
  ProjectTitle,
  ProjectSection,
  BlockParagraph,
  BlockQuote,
  BlockSections,
  BlockImage,
  BlockSpaceBetween,
  renderSection } from "./BaseComponents";

import baseUrl from "../constants/baseUrl";

const url = baseUrl.projects;

class ProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
    this.state = { project: {}, isLoad: false };
  }

  componentDidMount() {
    axios.get(`/projects/${this.id}`).then((res) => {
      let uploadedProject = res.data[0];
      this.setState({ project: uploadedProject, isLoad: true });
      console.log(uploadedProject);
    });
  }

  render() {
    let project = this.state.project;
    return (
      <>
        <Block>
          {this.state.isLoad && (
            <>
              {/* <Title>Projetos</Title> */}
              
              <SubtitleDateContainer>
                <Subtitle>{project.category}</Subtitle>
                <Date>{project.date}</Date>
              </SubtitleDateContainer>
            
                  <WideOpenImage>
                    <LargeDevice>
                      <img width={"100%"} style={{borderRadius: "2px"}} src={url + project.wideCoverUrl} />
                    </LargeDevice>
                    <Mobile>
                      <img
                        width={"100%"}
                        src={url + project.thumbnailSquareUrl}
                        style={{borderRadius: "2px"}}
                      />
                    </Mobile>
                  </WideOpenImage>
                <ProjectTitle>{project.title}</ProjectTitle>
        
              <BlockSections>
                {this.state.project.sections.map((section)=><Fade duration={500}>{renderSection(section)}</Fade>)}
              </BlockSections>
            </>
          )}
        </Block>
        {this.state.isLoad && <ContactUs />}
      </>
    );
  }
}

export default ProjectPage;
