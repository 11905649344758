import React , { useState, useEffect, useRef } from "react";
import styled, { keyframes} from "styled-components";
import { Link } from "react-router-dom";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import Logo from "./LogoBuril";
import DropdownHeader from "./DropdownHeader";
import NavBarData from "../assets/content/NavBarData";

// const ColorIn = keyframes`
//   from {
//     background: #171718;
//   }

//   to {
//     background: white;
//   }
// `;

// const ColorOut = keyframes`
//   from {
//     background: white;
//   }

//   to {
//     background: #171718;
//   }
// `;

/* animation:  ${props => props.state ? ColorOut : ColorIn} 500ms ease-in; */

const HeaderContainer = styled.div`
  z-index: 1000;
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  background: #171718;
  user-select: none;
  cursor: pointer;
  @media ${mediaQueries.extraSmallDevices} {
    justify-content: center;
  }
  @media ${mediaQueries.smallDevices} {
    justify-content: flex-start;
  }
  @media ${mediaQueries.mediumDevices} {
    justify-content: flex-start;
  }
  @media ${mediaQueries.largeDevices} {
    justify-content: flex-start;
  }
  @media ${mediaQueries.extraLargeDevices} {
    justify-content: flex-start;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  width: 70%;
  align-content: center;
  background: #171718;
  z-index: 1000;
`;

const Menu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: white;
  @media ${mediaQueries.smallDevices} {
    justify-content: space-between;
  }
  @media ${mediaQueries.mediumDevices} {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-around;
  }
  @media ${mediaQueries.largeDevices} {
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-around;
  }
  @media ${mediaQueries.extraLargeDevices} {
    justify-content: space-around;
  }
`;

const LogoModuleContainer = styled.div`
  background: #171718;
  &:hover {
    text-decoration: none;
  }
  @media ${mediaQueries.extraSmallDevices} {
    width: 300px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 300px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 200px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 250px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 300px;
  }
  z-index: 1000;
`;

const LogoContainer = styled.div`
  margin: 10px;
  &:hover {
    text-decoration: none;
  }
`;

const Header = () => {
  // const [animationState, setAnimationState] = useState(0);
  // const myStateRef = React.useRef(animationState);
  // const setAnimationStateRef = (data) => {
  //   myStateRef.current = data;
  //   setAnimationState(data);
  // };

  // const handleScroll = () => {
  //   if(window.pageYOffset > 10 && myStateRef==0){
  //     setAnimationStateRef(myStateRef.current.setAnimationState(1));
  //   }else if( window.pageYOffset <= 10 &&  myStateRef==1){
  //     setAnimationStateRef(myStateRef.current.setAnimationState(0));
  //   }
  //   console.log(myStateRef);
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });

  //   return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  
  const renderSections = (array) => {
    return array.map(({ title, sections, link }) => {
      if (link === undefined) {
        return <DropdownHeader text={title} sections={sections} key={title} />; // Normal Dropdown
      } else {
        return (
          <DropdownHeader
            text={title}
            sections={sections}
            link={link}
            key={title}
          />
        ); // Dumby Dropdown
      }
    });
  };
  
  return (
    <HeaderContainer>
      <LogoModuleContainer as={Link} to={"/"}>
        <LogoContainer>
          <Logo width={"100%"} height={"54%"} />
        </LogoContainer>
      </LogoModuleContainer>
      <MenuContainer>
        <Menu>{renderSections(NavBarData)}</Menu>
      </MenuContainer>
    </HeaderContainer>
  );
};

export default Header;
