import React, { useState } from "react";
import styled from "styled-components";
import contacts from "../assets/content/contactData";
import { CopyToClipboard } from "react-copy-to-clipboard";
import breakpoints from "../constants/breakpoints";
import mediaQueries from "../constants/mediaQueries";
import ContactUs from "./ContactUs";
import { LargeDevice } from "../components/ReactResponsiveComponents";
import Fade from 'react-reveal/Fade';
import baseUrl from "../constants/baseUrl";

const url = baseUrl.contacts;

const colors = {
  color1: "white",
  color2: "white",
  backgroundColor1: "#171718",
  backgroundColor2: "#0071BC"
}

const PageTitle = styled.div`
  font-family: "Druk", sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
    margin-bottom: 15px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
    margin-bottom: 15px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 22px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 27px;
    margin-bottom: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  @media ${mediaQueries.extraSmallDevices} {
    width: 95%;
    flex-direction: column;
    margin: 0px auto;
    margin-top: 20px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 95%;
    flex-direction: column;
    margin: 0px auto;
    margin-top: 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 90%;
    margin: 60px auto;
    flex-direction: column;
  }
  @media ${mediaQueries.largeDevices} {
    width: 90%;
    margin: 60px auto;
    flex-direction: column;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 85%;
    margin: 60px auto;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    margin-bottom: 0px;
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    margin-bottom: 0px;
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-bottom: 0px;
    width: 100%;
  }
  @media ${mediaQueries.largeDevices} {
    margin-bottom: 0px;
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-bottom: 0px;
    width: 100%;
  }
`;

const ContactsContainer = styled.div`
  display: flex;
  flex-basis: 4;
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
    flex-direction: column;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
    flex-direction: column;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 100%;
    flex-direction: row;
  }
  @media ${mediaQueries.largeDevices} {
    width: 100%;
    flex-direction: row;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 100%;
    flex-direction: row;
  }
`;

const Section = styled.div`
  display: flex;
  background: ${props => props.background};
  color: ${props => props.color};
  user-select: none;
  cursor: pointer;
  transition: 300ms;
  &:hover{
    transition: 300ms;
    background: grey;
    
  }
  @media ${mediaQueries.extraSmallDevices} {
    padding: 15px;
    padding-bottom: 30px;
  }
  @media ${mediaQueries.smallDevices} {
    padding: 15px;
    padding-bottom: 30px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding: 15px;
    padding-bottom: 30px;
    flex-grow: 1;
  }
  @media ${mediaQueries.largeDevices} {
    padding: 15px;
    padding-bottom: 30px;
    flex-grow: 1;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 25px;
    padding-bottom: 50px;
    flex-grow: 1;
  }
`;

const Title = styled.div`
  font-family: "Druk", sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 16px;
    margin-bottom: 5px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
    margin-bottom: 5px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 15px;
    margin-bottom: 10px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 17px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 20px;
    margin-bottom: 30px;
  }
`;

const Block = styled.div`
  font-family: "DM Sans", sans-serif;
`;

const Body = styled.div`
  font-family: "DM Sans", sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 16px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 18px;
  }
`;

const Description = styled.div`
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 11px;
    width: 130px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 11px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 10px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 11px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 14px;
  }
`;

const CopyToClipboardNotification = styled.div`
  font-family: "Russo One";
  font-size: 12px;
  padding: 25px;
  color: red;
`;

const Contacts = () => {
  const { phone, address1, address2, email } = contacts;
  const [text, setText] = useState("");
  return (
    <Fade>
      <Container>
        <PageTitle>CONTACTOS</PageTitle>
        <ImageContainer>
          <img width={"100%"} src={url} />
        </ImageContainer>
        <ContactsContainer>
          <CopyToClipboard
            text={phone.body}
            onCopy={() => setText("Email Arte copiado para o clipboard")}
          >
            <Section background={colors.backgroundColor1} color={colors.color1}>
              <Block>
                <Title>{phone.title}</Title>
                <Body>{phone.body}</Body>
                <Body>{phone.phone}</Body>
                <Description>{phone.description}</Description>
              </Block>
            </Section>
          </CopyToClipboard>
          <CopyToClipboard
            text={email.body}
            onCopy={() => setText("Email Produção copiado para o clipboard")}
          >
            <Section background={colors.backgroundColor2} color={colors.color2}>
              <Block>
                <Title>{email.title}</Title>
                <Body>{email.body}</Body>
                <Body>{email.phone}</Body>
                <Description>{email.description}</Description>
              </Block>
            </Section>
          </CopyToClipboard>
          <CopyToClipboard
            text={address1.body + "," + address1.description}
            onCopy={() => setText("Morada copiada para o clipboard")}
          >
            <Section background={colors.backgroundColor1} color={colors.color1}>
              <Block>
                <Title>{address1.title}</Title>
                <Body>{address1.body}</Body>
                <Description>{address1.description}</Description>
              </Block>
            </Section>
            </CopyToClipboard>
            <CopyToClipboard
              text={address2.body + "," + address2.description}
              onCopy={() => setText("Morada copiada para o clipboard")}
            >
              <Section background={colors.backgroundColor2} color={colors.color2}>
                <Block>
                  <Title>{address2.title}</Title>
                  <Body>{address2.body}</Body>
                  <Description>{address2.description}</Description>
                </Block>
              </Section>
          </CopyToClipboard>
        </ContactsContainer>
        <LargeDevice>
          <CopyToClipboardNotification>{text}</CopyToClipboardNotification>
        </LargeDevice>
        
      </Container>
      <ContactUs />
    </Fade>
  );
};

export default Contacts;
