import React from 'react';

export default function logo(props) {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 608.1 139.1" enableBackground="new 0 0 608.1 139.1" xmlSpace="preserve" width="608.1" height="139.1" {...props}>
      <rect y={0} display="none" width="643.1" height="141.9" />
      <g>
        <path d="M45.8-167.2v-42.9h14l9.4,17.8l9.3-17.8h14v42.9h-14V-185l-9,17.8h-0.8l-9-17.8v17.8H45.8z" />
        <path d="M91.3-167.2l14.1-42.9h21.3l14.4,42.9h-16.2l-2.7-7.7h-12.8l-2.7,7.7H91.3z M116.1-200.6l-6.2,17h11.8L116.1-200.6z" />
        <path d="M169.4-180h13.4v12.8h-19c-4.7,0-8.9-0.8-12.6-2.5c-3.8-1.7-6.7-4.1-8.9-7.4c-2.2-3.3-3.3-7.4-3.3-12.2
        		c0-3.4,0.6-6.4,1.9-9c1.3-2.6,3.1-4.8,5.4-6.6c2.3-1.8,4.9-3.1,7.9-3.9c3-0.9,6.2-1.3,9.5-1.3h11.8h7.3v12.8h-13.4
        		c-3,0-5.2,0.1-6.7,0.3c-1.4,0.2-2.7,0.5-3.9,0.9s-2.1,1.1-3,2.2c-0.9,1-1.4,2.9-1.5,5.5c0.1,2.5,0.6,4.4,1.5,5.4
        		c0.9,1.1,2,1.8,3.3,2.2c1.3,0.4,2.7,0.6,4,0.7C164.6-180,166.7-180,169.4-180z" />
        <path d="M209.2-195.3v-14.8h14v42.9h-14v-15.5H198v15.5h-14v-42.9h14v14.8H209.2z" />
        <path d="M222-167.2l14.1-42.9h21.3l14.4,42.9h-16.2l-2.7-7.7h-12.8l-2.7,7.7H222z M246.9-200.6l-6.2,17h11.8L246.9-200.6z" />
        <path d="M271.2-167.2v-42.9h25.3c3.4,0,6.5,0.5,9.1,1.4c2.6,0.9,4.8,2.1,6.6,3.7c1.8,1.5,3.3,3.6,4.5,6.3c1.2,2.6,1.8,6.1,1.8,10.3
        		c-0.2,3.9-0.9,7.2-2,10c-1.1,2.7-2.5,4.9-4.3,6.5c-1.8,1.6-4,2.8-6.6,3.7c-2.6,0.9-5.9,1.3-9.8,1.2H271.2z M285.6-199.5v21.6h8.2
        		c1.8,0,3.3-0.4,4.5-1.2c1.1-0.8,2.1-1.8,2.8-3c0.7-1.2,1.2-3.4,1.4-6.6c-0.1-2.9-0.5-5-1.2-6.4c-0.7-1.4-1.7-2.5-2.9-3.2
        		c-1.2-0.8-2.7-1.2-4.6-1.2H285.6z" />
        <path d="M368.5-188.4c0,4.7-1,8.7-2.9,12c-2,3.3-4.8,5.8-8.5,7.5c-3.7,1.7-8.2,2.5-13.3,2.5c-5.2,0-9.6-0.8-13.3-2.5
        		c-3.7-1.7-6.5-4.2-8.4-7.4c-1.9-3.3-2.9-7.3-2.9-12c0-3.6,0.5-6.7,1.6-9.5c1.1-2.7,2.7-5,4.8-6.9c2.1-1.9,4.7-3.3,7.7-4.2
        		c3.1-1,6.5-1.4,10.4-1.4c5.1,0,9.6,0.8,13.3,2.5c3.7,1.7,6.6,4.2,8.5,7.5C367.5-197.1,368.5-193.1,368.5-188.4z M334.4-188.3
        		c0,2.8,0.6,5.2,1.9,7.2c1.2,2,3.7,3.1,7.3,3.1c2.8,0,4.9-0.5,6.3-1.6c1.4-1.1,2.3-2.3,2.6-3.7c0.3-1.4,0.6-3,0.7-5
        		c-0.1-2.8-0.8-5.2-2-7.2c-1.2-2.1-3.8-3.1-7.6-3.1C337.5-198.6,334.4-195.1,334.4-188.3z" />
      </g>
      <text transform="matrix(1 0 0 1 524.4897 -234.4669)" fill="#00A99D" fontFamily="'SegoeUI-Semilight'" fontSize="56.6929">workshop</text>
      <g>
        <path fill="#0071BC" d="M668.3-105.8c-1,0-1.8-0.2-2.6-0.6c-0.8-0.4-1.1-1-1.1-1.8c0,0.6-0.3,1.2-1,1.7c-0.7,0.5-1.6,0.7-2.8,0.7
        		h-8.9c-0.9,0-1.7-0.2-2.4-0.6c-0.7-0.4-1.1-0.8-1.2-1.4l-5.2-31.8c0-0.6,0.3-1.1,1-1.6c0.6-0.4,1.5-0.6,2.7-0.6h7.1
        		c1,0,1.9,0.2,2.6,0.6c0.7,0.4,1.1,1,1.2,1.9c0.1-0.8,0.6-1.4,1.3-1.8c0.7-0.4,1.5-0.6,2.4-0.6h7.1c0.9,0,1.7,0.2,2.4,0.7
        		c0.7,0.5,1.2,1.3,1.4,2.4c0.2-1.1,0.7-1.9,1.4-2.4c0.7-0.5,1.6-0.7,2.5-0.7h7.1c1.1,0,2,0.2,2.7,0.7c0.6,0.4,1,1,1,1.6l-5.9,31.8
        		c-0.1,0.5-0.5,1-1.2,1.3c-0.7,0.4-1.5,0.5-2.4,0.5H668.3z M677.3-107.8l5.9-31.9h-7.1l-3.8,23.5l-3.9-23.5h-7.1l-4.5,23.5
        		l-3.1-23.5h-7.1l5.2,31.9h8.9l4-22.1l3.5,22.1H677.3z" />
        <path fill="#0071BC" d="M688.1-133.8c0-2.8,1.1-4.9,3.4-6.3c2.3-1.4,5.9-2.2,10.9-2.2c4.7,0,8.3,0.7,10.7,2.2
        		c2.4,1.4,3.6,3.5,3.6,6.3v20.1c0,2.8-1.2,4.8-3.6,6.3c-2.4,1.4-6,2.1-10.7,2.1c-5,0-8.6-0.7-10.9-2.1c-2.3-1.4-3.4-3.5-3.4-6.3
        		V-133.8z M691.8-113.7c0,2.1,0.8,3.6,2.5,4.7c1.7,1.1,4.4,1.6,8.1,1.6c7.1,0,10.6-2.1,10.6-6.3v-20.1c0-2.1-0.9-3.7-2.7-4.7
        		c-1.8-1.1-4.4-1.6-7.9-1.6c-3.7,0-6.4,0.5-8.1,1.6c-1.7,1.1-2.5,2.7-2.5,4.7V-113.7z M705.6-113.9c0,0.7-0.2,1.3-0.6,1.6
        		c-0.4,0.4-1.3,0.5-2.5,0.5c-1.3,0-2.1-0.2-2.6-0.5c-0.4-0.3-0.7-0.9-0.7-1.6v-19.8c0-0.7,0.2-1.3,0.7-1.6c0.4-0.4,1.3-0.5,2.6-0.5
        		c1.3,0,2.1,0.2,2.5,0.5c0.4,0.4,0.6,0.9,0.6,1.6V-113.9z" />
        <path fill="#0071BC" d="M724.1-105.8c-1,0-1.9-0.2-2.6-0.6c-0.7-0.4-1.1-0.9-1.1-1.4v-31.9c0-0.6,0.3-1.1,1.1-1.4
        		c0.7-0.4,1.6-0.6,2.6-0.6h6.5c0.4,0,1,0.1,1.6,0.3c1.3-0.4,3-0.6,4.9-0.6c0.5,0,1.1,0,1.7,0.1c0.6,0.1,1.2,0.2,1.8,0.4
        		c0.5,0.2,1,0.4,1.3,0.7c0.3,0.3,0.5,0.6,0.5,1.1v4.8c0,0.6-0.3,1.2-1,1.5c-0.7,0.4-1.5,0.6-2.4,0.6c-0.6,0-1-0.1-1.4-0.2
        		c-0.4-0.1-0.9-0.2-1.8-0.2h-0.4v25.6c0,0.6-0.4,1.1-1.1,1.4c-0.7,0.4-1.6,0.6-2.6,0.6H724.1z M731.7-107.8V-134
        		c0.4-0.5,1.1-0.9,1.8-1.1c0.8-0.2,1.5-0.3,2.3-0.3c1.3,0,2.3,0.1,3,0.4v-4.8c-0.7-0.1-1.3-0.2-1.7-0.2c-2.8,0-4.9,0.8-6.2,2.3
        		c0-0.7-0.1-1.3-0.3-2h-6.5v31.9H731.7z" />
        <path fill="#0071BC" d="M763.5-105.8c-0.8,0-1.5-0.2-2.2-0.5c-0.7-0.3-1.2-0.7-1.4-1.1l-0.6-1.3v0.8c0,0.6-0.4,1.1-1.1,1.4
        		c-0.7,0.4-1.6,0.6-2.6,0.6h-7.5c-1,0-1.9-0.2-2.6-0.6c-0.7-0.4-1.1-0.9-1.1-1.4v-42.3c0-0.6,0.3-1.1,1.1-1.5
        		c0.7-0.4,1.6-0.6,2.6-0.6h7.5c1,0,1.9,0.2,2.6,0.6c0.7,0.4,1.1,0.9,1.1,1.5v11.2l0.9-1.4c0.3-0.4,0.7-0.7,1.4-1
        		c0.7-0.2,1.4-0.4,2.1-0.4h7.8c1.1,0,2,0.2,2.7,0.6c0.7,0.4,1.1,0.8,1.1,1.4c0,0.3-0.1,0.6-0.3,0.8l-8.7,13.3l9,17.3
        		c0.1,0.3,0.2,0.5,0.2,0.7c0,0.5-0.4,1-1.1,1.4c-0.7,0.4-1.6,0.6-2.7,0.6H763.5z M771.8-107.8l-9.3-18l9-13.9h-7.8
        		c-1.7,2.7-3.3,5.2-4.7,7.5c-1.4,2.3-2.6,4.3-3.5,6.1c0-1.2,0-2.3,0-3.4c0-1.1,0-2.4,0-4v-16.7h-7.5v42.3h7.5v-7.6
        		c0-0.9,0-1.8,0-2.5c0-0.8,0-1.5,0-2.1c0-0.7,0-1.3,0-1.9c0-0.6,0-1.3,0-2c0.3,0.6,0.6,1.3,1,2c0.3,0.7,0.8,1.6,1.3,2.7
        		c0.6,1.1,1.3,2.6,2.2,4.4c0.9,1.8,2.1,4.2,3.5,7.1H771.8z" />
        <path fill="#0071BC" d="M803.7-113.2c0,2.8-1.2,4.8-3.6,6.1c-2.4,1.3-6,1.9-10.8,1.9c-4.6,0-8-0.7-10.3-2s-3.4-3.4-3.4-6.2v-5
        		c0-0.6,0.4-1.1,1.1-1.5c0.7-0.4,1.6-0.6,2.6-0.6h5.6l-1.6-1h0.1c-1.6-0.9-2.9-1.7-3.9-2.5c-1-0.7-1.8-1.4-2.4-2.1
        		c-0.6-0.7-1-1.4-1.2-2.1c-0.2-0.8-0.3-1.6-0.3-2.6v-3.1c0-3,1.2-5.2,3.6-6.5c2.4-1.3,5.8-1.9,10.4-1.9c2.7,0,5,0.2,6.8,0.5
        		c1.8,0.3,3.2,0.8,4.2,1.5c1.1,0.7,1.8,1.6,2.2,2.6c0.4,1.1,0.7,2.3,0.7,3.7v5c0,0.6-0.4,1.1-1.1,1.4c-0.7,0.4-1.6,0.6-2.6,0.6h-5.1
        		l0.6,0.3c0.9,0.5,1.8,1.1,2.7,1.6c0.9,0.6,1.5,0.9,1.9,1.2c0.8,0.6,1.4,1.1,1.9,1.7c0.5,0.5,0.9,1.1,1.2,1.7
        		c0.3,0.6,0.5,1.2,0.6,1.8c0.1,0.7,0.1,1.4,0.1,2.2V-113.2z M800.1-116.4c0-1.3-0.2-2.4-0.6-3.3c-0.4-0.9-1.3-1.8-2.5-2.7
        		c-1.3-0.9-2.7-1.8-4.2-2.6c-1.5-0.8-3-1.7-4.5-2.8c-0.7-0.5-1.1-0.9-1.2-1.3c-0.1-0.4-0.2-0.8-0.2-1.3v-3.5c0-1.3,1-2,2.9-2
        		c1,0,1.7,0.2,2.3,0.5c0.6,0.3,0.9,0.8,0.9,1.5v5h7v-5c0-2.2-0.7-3.8-2.1-4.8c-1.4-1-4.2-1.5-8.2-1.5c-3.3,0-5.8,0.5-7.6,1.4
        		c-1.8,0.9-2.6,2.6-2.6,4.9v3.1c0,1,0.2,2,0.5,2.8c0.3,0.8,1,1.6,2,2.3c1.5,1,3,2,4.5,2.9c1.5,0.9,3,1.9,4.5,2.9
        		c0.7,0.5,1.2,1,1.4,1.3c0.2,0.4,0.3,0.9,0.3,1.6v3.4c0,1.3-1,2-3.1,2c-1,0-1.7-0.2-2.3-0.5c-0.6-0.3-0.9-0.8-0.9-1.5v-4.9h-7v5
        		c0,2.1,0.8,3.6,2.5,4.6c1.7,1,4.2,1.4,7.5,1.4c3.6,0,6.3-0.4,8.1-1.3c1.8-0.9,2.7-2.4,2.7-4.5V-116.4z" />
        <path fill="#0071BC" d="M824.8-105.8c-0.6,0-1.2-0.1-1.8-0.3c-0.5-0.2-1-0.4-1.3-0.7c-0.3,0.3-0.7,0.5-1.3,0.7
        		c-0.5,0.2-1.2,0.3-1.9,0.3H811c-1,0-1.9-0.2-2.6-0.6c-0.7-0.4-1.1-0.9-1.1-1.4v-42.3c0-0.6,0.3-1.1,1.1-1.5
        		c0.7-0.4,1.6-0.6,2.6-0.6h7.6c1,0,1.9,0.2,2.6,0.6c0.7,0.4,1.1,0.9,1.1,1.5v8.1c0.4-0.1,0.9-0.1,1.5-0.2c0.5,0,1.1-0.1,1.6-0.1
        		c7.2,0,10.8,2.6,10.8,7.9v26.5c0,0.6-0.4,1.1-1,1.4c-0.7,0.4-1.6,0.6-2.6,0.6H824.8z M832.5-107.8v-26.5c0-3.9-2.4-5.8-7.2-5.8
        		c-2.8,0-5.1,0.6-6.8,1.8v-11.7H811v42.3h7.6v-26.9c0.4-0.5,0.9-0.8,1.5-1c0.6-0.2,1.3-0.3,2-0.3c1.8,0,2.7,0.6,2.7,1.9v26.3H832.5z
        		" />
        <path fill="#0071BC" d="M839.7-133.8c0-2.8,1.1-4.9,3.4-6.3c2.3-1.4,5.9-2.2,10.9-2.2c4.7,0,8.3,0.7,10.7,2.2
        		c2.4,1.4,3.6,3.5,3.6,6.3v20.1c0,2.8-1.2,4.8-3.6,6.3c-2.4,1.4-6,2.1-10.7,2.1c-5,0-8.6-0.7-10.9-2.1c-2.3-1.4-3.4-3.5-3.4-6.3
        		V-133.8z M843.4-113.7c0,2.1,0.8,3.6,2.5,4.7c1.7,1.1,4.4,1.6,8.1,1.6c7.1,0,10.6-2.1,10.6-6.3v-20.1c0-2.1-0.9-3.7-2.7-4.7
        		c-1.8-1.1-4.4-1.6-7.9-1.6c-3.7,0-6.4,0.5-8.1,1.6c-1.7,1.1-2.5,2.7-2.5,4.7V-113.7z M857.2-113.9c0,0.7-0.2,1.3-0.6,1.6
        		c-0.4,0.4-1.3,0.5-2.5,0.5c-1.3,0-2.1-0.2-2.6-0.5c-0.4-0.3-0.7-0.9-0.7-1.6v-19.8c0-0.7,0.2-1.3,0.7-1.6c0.4-0.4,1.3-0.5,2.6-0.5
        		c1.3,0,2.1,0.2,2.5,0.5c0.4,0.4,0.6,0.9,0.6,1.6V-113.9z" />
        <path fill="#0071BC" d="M875.7-98.6c-1,0-1.9-0.2-2.6-0.6c-0.7-0.4-1.1-0.9-1.1-1.4v-39c0-0.6,0.3-1.1,1.1-1.4
        		c0.7-0.4,1.6-0.6,2.6-0.6h6.5c0.5,0,1.1,0.1,1.7,0.3c1.5-0.4,3.4-0.6,5.6-0.6c3.8,0,6.7,0.7,8.7,2c2,1.3,3,3.4,3,6.1v20.8
        		c0,2.8-1,4.8-2.9,6c-1.9,1.2-4.9,1.8-8.9,1.8c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.1-0.1v4.7c0,0.6-0.4,1.1-1.1,1.4
        		c-0.7,0.4-1.6,0.6-2.6,0.6H875.7z M883.3-100.7v-8.1c1.3,1,3.3,1.4,6,1.4c2.8,0,4.9-0.4,6.2-1.3c1.3-0.9,2-2.4,2-4.4V-134
        		c0-2-0.7-3.5-2.1-4.5c-1.4-1-3.4-1.5-6-1.5c-2,0-3.5,0.2-4.6,0.6c-1.1,0.4-1.9,1-2.5,1.8c0-0.7-0.1-1.4-0.3-2.1h-6.5v39H883.3z
        		 M889.9-113.6c0,1.3-1,1.9-3.1,1.9c-0.8,0-1.5-0.1-2.1-0.3c-0.6-0.2-1.1-0.6-1.4-1.1v-21c0.3-0.5,0.8-0.9,1.4-1.1
        		c0.6-0.2,1.3-0.3,2.1-0.3c1.1,0,1.9,0.2,2.3,0.5c0.5,0.3,0.7,0.8,0.7,1.5V-113.6z" />
      </g>
      <g>
        <path fill="#FFDF8C" d="M305.8-113.9h-8.5l-4.9-22.9h-0.1l-4.9,22.9h-8.5l-8.9-31.4h8.9l4.9,22.6h0.1l4.4-22.6h8.3l4.7,22.6h0.1
        		l4.9-22.6h8.5L305.8-113.9z" />
        <path fill="#FFDF8C" d="M348.8-129.6c0,5.1-1.5,9.2-4.4,12.1s-6.8,4.4-11.6,4.4c-4.7,0-8.5-1.5-11.5-4.4c-3-2.9-4.5-7-4.5-12.2
        		c0-5.2,1.5-9.3,4.5-12.2c3-2.9,6.8-4.4,11.5-4.4c4.8,0,8.6,1.5,11.6,4.5C347.3-138.7,348.8-134.7,348.8-129.6z M340.3-129.6
        		c0-3-0.6-5.4-1.9-7c-1.2-1.7-3.1-2.5-5.6-2.5c-2.3,0-4.2,0.8-5.5,2.4s-2,4-2,7.2c0,3.2,0.6,5.5,1.9,7.2c1.3,1.6,3.1,2.4,5.5,2.4
        		c2.5,0,4.3-0.8,5.6-2.5C339.7-124.2,340.3-126.6,340.3-129.6z" />
        <path fill="#FFDF8C" d="M355-145.4h7.9v5.5h0.1c1-2.1,2.1-3.6,3.5-4.7c1.4-1.1,3.1-1.6,5.1-1.6c0.5,0,1.1,0,1.6,0.1v8.4
        		c-0.4-0.1-1.2-0.1-2.4-0.1c-2.6,0-4.5,0.6-5.7,1.9c-1.3,1.2-1.9,3.1-1.9,5.6v16.4H355V-145.4z" />
        <path fill="#FFDF8C" d="M386.6-113.9h-8.3v-42.4h8.3v22.9l10.4-11.9h10.2l-11.1,12.2l11.4,19.3h-9.9l-7.5-13.5l-3.4,3.5V-113.9z" />
        <path fill="#FFDF8C" d="M428.3-135.7c-0.2-1.4-0.7-2.4-1.5-3.2c-0.8-0.8-2.2-1.1-4-1.1c-1.7,0-3,0.3-3.8,0.8
        		c-0.8,0.6-1.2,1.3-1.2,2.1c0,0.8,0.3,1.5,1,1.9c0.7,0.5,3.3,1.2,7.9,2.3c4.7,1.1,7.6,2.4,8.9,3.8c1.3,1.5,1.9,3.3,1.9,5.4
        		c0,3.5-1.2,6.1-3.7,8s-6.1,2.8-11,2.8c-4.3,0-7.6-0.9-10.1-2.7c-2.4-1.8-3.8-4.5-4.2-8.3h8.5c0,1.6,0.5,2.9,1.6,3.6
        		s2.6,1.2,4.8,1.2c1.8,0,3.2-0.3,4.2-0.8c1-0.5,1.4-1.3,1.4-2.2c0-0.9-0.4-1.6-1.1-2.1c-0.7-0.5-3.1-1.2-7.1-2.1
        		c-4.5-1-7.5-2.2-9-3.8s-2.2-3.4-2.2-5.6c0-3.1,1.1-5.7,3.3-7.6c2.2-2,5.5-3,9.9-3c4.3,0,7.5,0.9,9.8,2.7c2.3,1.8,3.5,4.4,3.8,7.8
        		H428.3z" />
        <path fill="#FFDF8C" d="M472-113.9h-8.3v-19.5c0-1.9-0.4-3.4-1.3-4.3c-0.9-1-2.2-1.4-4.1-1.4c-2.1,0-3.7,0.7-4.8,2
        		c-1.1,1.4-1.7,3.1-1.7,5.2v18h-8.3v-42.4h8.3v15h0.1c1.9-3.2,5-4.8,9.2-4.8c3.3,0,5.9,1,7.9,2.9c2,1.9,3,4.5,3,7.6V-113.9z" />
        <path fill="#FFDF8C" d="M509.8-129.6c0,5.1-1.5,9.2-4.4,12.1s-6.8,4.4-11.6,4.4c-4.7,0-8.5-1.5-11.5-4.4s-4.5-7-4.5-12.2
        		c0-5.2,1.5-9.3,4.5-12.2c3-2.9,6.8-4.4,11.5-4.4c4.8,0,8.6,1.5,11.6,4.5C508.3-138.7,509.8-134.7,509.8-129.6z M501.2-129.6
        		c0-3-0.6-5.4-1.9-7c-1.2-1.7-3.1-2.5-5.6-2.5c-2.3,0-4.2,0.8-5.5,2.4s-2,4-2,7.2c0,3.2,0.6,5.5,1.9,7.2c1.3,1.6,3.1,2.4,5.5,2.4
        		c2.5,0,4.3-0.8,5.6-2.5C500.6-124.2,501.2-126.6,501.2-129.6z" />
        <path fill="#FFDF8C" d="M523.9-140.8h0.1c2-3.6,5.1-5.4,9.4-5.4c3.8,0,6.9,1.4,9.4,4.3s3.7,6.8,3.7,11.9c0,4.9-1.1,9-3.4,12.2
        		c-2.3,3.2-5.6,4.7-9.8,4.7c-4.1,0-7.1-1.6-8.9-4.8h-0.1v16.2H516v-43.7h7.9V-140.8z M523.9-128.9c0,2.8,0.6,5,1.9,6.6
        		c1.3,1.6,3.1,2.4,5.4,2.4c2.1,0,3.8-0.9,5-2.7c1.2-1.8,1.8-4.1,1.8-6.8c0-3.1-0.6-5.5-1.9-7.1c-1.2-1.6-3-2.5-5.2-2.5
        		c-2.4,0-4.1,0.9-5.3,2.6C524.4-134.7,523.9-132.2,523.9-128.9z" />
      </g>
      <text transform="matrix(1 0 0 1 -10.106 -336.3825)" fontFamily="'StarJedi'" fontSize="45.3543">MACHADo</text>
      <g>
        <path fill="#FFFFFF" d="M51.6,84.6V22.7h42.3c6.8,0.1,11.9,1.2,15.5,3.5c3.5,2.3,5.7,6.1,6.4,11.5c0.3,4.1-0.6,7.3-2.6,9.7
        		c-2,2.3-5.3,4.3-9.9,5.8v0.2c2.3,0.3,4.3,0.9,6.3,1.8c1.9,0.9,3.5,2,4.8,3.3c1.3,1.3,2.3,2.7,3,4.3c0.7,1.6,1.1,3.2,1.1,4.7
        		c0,4.9-1.3,8.6-3.8,11.1c-2.5,2.5-5.7,4.2-9.7,4.9c-3.9,0.8-8.7,1.1-14.3,1.1H51.6z M73,37.7v9.4h15c0.8,0,1.7-0.4,2.6-1.3
        		c0.9-0.9,1.4-2,1.5-3.5c-0.1-1.7-0.7-2.9-1.7-3.6s-1.8-1-2.5-1H73z M73,60.6v11.5h13.8c2.4,0.1,4.4-0.4,6.2-1.5
        		c1.8-1.1,2.7-2.5,2.7-4.1c0.2-1.8-0.6-3.2-2.2-4.2c-1.6-1-3.9-1.5-6.7-1.5H73z" />
        <path fill="#FFFFFF" d="M139.5,22.7v36.6c-0.2,4.2,0.6,7.1,2.4,8.7c1.8,1.6,4.5,2.5,8.3,2.6c3-0.1,5.6-0.9,7.6-2.7
        		c2-1.7,3-4.6,2.9-8.7V22.7h20.2V59c-0.1,5.1-0.9,9.3-2.6,12.3c-1.6,3.1-3.7,5.6-6.1,7.7c-2.5,2.1-5.6,3.7-9.3,4.8
        		s-7.9,1.8-12.5,1.9c-3.8-0.1-7.7-0.6-11.6-1.6c-3.9-1-7.4-2.5-10.3-4.5c-2.9-2.1-5.2-4.7-6.8-7.9c-1.6-3.2-2.4-7.4-2.4-12.7V22.7
        		H139.5z" />
        <path fill="#FFFFFF" d="M183.8,84.6V22.7h40.3c5,0.5,9.1,1.8,12.3,3.9c3.2,2.2,5.5,5.1,7,8.8c1.1,5.5,1.2,9.7,0.2,12.7
        		c-1,3-2.8,5.5-5.4,7.5c-2.6,2-6.3,3.6-11.1,4.7l4.6,5.9h18.5v18.4h-24.5l-19.6-20.5v20.5H183.8z M206.2,38.1v11.4h15.6
        		c0.8,0,1.7-0.3,2.7-0.9s1.5-2.2,1.5-4.7c-0.1-2.4-0.7-3.9-1.7-4.7c-1-0.8-1.9-1.1-2.6-1.1H206.2z" />
        <path fill="#FFFFFF" d="M249.5,22.7h20.2v61.9h-20.2V22.7z" />
        <path fill="#FFFFFF" d="M273.9,84.6V22.7h20.2v43.5h33.5v18.4H273.9z" />
      </g>
      <g>
        <path fill="#FFDF8C" d="M694.9-71.8c0.6,0,1.2,0.4,1.3,1.1l4.3,22.3c0.1,0.7,0.8,0.6,0.9,0l2.9-36.5c0.1-0.8,0.7-1.1,1.3-1.1h3.5
        		c0.9,0,1.4,0.5,1.4,1.3c0,0.5-0.1,1.8-0.3,3.3l-4.2,43.9c-0.1,0.7-0.7,1.1-1.3,1.1h-6.4c-0.6,0-1.2-0.4-1.3-1.1l-4-21.4
        		c-0.1-0.4-0.4-0.4-0.4,0l-4,21.4c-0.1,0.7-0.7,1.1-1.3,1.1h-6.4c-0.6,0-1.3-0.4-1.3-1.1l-4.2-43.9c-0.1-1.6-0.3-2.8-0.3-3.3
        		c0-0.8,0.5-1.3,1.4-1.3h3.5c0.6,0,1.3,0.3,1.3,1.1l2.9,36.5c0.1,0.6,0.7,0.7,0.9,0l4.3-22.3c0.1-0.7,0.7-1.1,1.3-1.1H694.9z" />
        <path fill="#FFDF8C" d="M719.7-41.6c-2.4-3.6-2.6-7.6-2.6-12.5c0-5,0.2-8.9,2.6-12.5c2.4-3.6,6.5-5.9,12-5.9c5.5,0,9.6,2.3,12,5.9
        		c2.4,3.6,2.6,7.6,2.6,12.5c0,5-0.2,8.9-2.6,12.5c-2.4,3.6-6.5,5.9-12,5.9C726.2-35.7,722.1-38,719.7-41.6z M738.2-45.4
        		c1.3-1.9,1.6-4.6,1.6-8.7c0-4.1-0.3-6.8-1.6-8.7c-1.1-1.7-3.3-3.3-6.5-3.3c-3.2,0-5.4,1.6-6.5,3.3c-1.3,1.9-1.6,4.6-1.6,8.7
        		c0,4.1,0.3,6.8,1.6,8.7c1.1,1.7,3.3,3.3,6.5,3.3C734.9-42.1,737.1-43.7,738.2-45.4z" />
        <path fill="#FFDF8C" d="M765.7-57.2v19.4c0,0.8-0.6,1.4-1.4,1.4h-3.5c-0.8,0-1.4-0.6-1.4-1.4v-32.6c0-0.8,0.6-1.4,1.4-1.4h2.8
        		c0.4,0,0.9,0.1,1,0.5c0.4,1,0.7,2.3,1.8,2.3c1.6,0,2.6-3.5,9.3-3.5c6.2,0,11.3,5.2,11.3,12c0,0.8-0.6,1.4-1.4,1.4h-3.6
        		c-0.8,0-1.4-0.6-1.4-1.4c0-3.4-2.2-5.7-6.6-5.7C769.3-66.2,765.7-63.5,765.7-57.2z" />
        <path fill="#FFDF8C" d="M824-38.9c0.4,0.4,0.5,0.6,0.5,1.1c0,0.8-0.6,1.4-1.5,1.4h-4.5c-0.9,0-1.6-0.7-2.1-1.3l-11.1-12.7
        		c-0.3-0.3-0.4-0.4-0.6-0.4c-0.2,0-0.4,0.1-0.4,0.5v12.4c0,0.8-0.6,1.4-1.4,1.4h-3.5c-0.8,0-1.4-0.6-1.4-1.4v-47.5
        		c0-0.8,0.6-1.4,1.4-1.4h3.5c0.8,0,1.4,0.6,1.4,1.4v26.2c0,0.4,0.1,0.5,0.4,0.5c0.1,0,0.3-0.1,0.6-0.4l11.3-11.8
        		c0.4-0.4,1.1-1.1,1.8-1.1h4.2c1,0,1.5,0.7,1.5,1.4c0,0.4-0.2,0.8-0.5,1.1l-13.2,13.5c-0.5,0.6-0.6,1.1,0,1.7L824-38.9z" />
        <path fill="#FFDF8C" d="M834.6-47.2c0-1.6,0.4-2.2,1.4-2.2h3.5c0.8,0,1.3,0.5,1.4,1.4c0.4,3.8,2.6,6.2,8,6.2c4.5,0,7.2-1.6,7.2-4.7
        		c0-3-1.9-4.3-8.1-5.3c-8.3-1.4-12-4.7-12-10.6c0-5.9,5.3-10.1,12.8-10.1c8.1,0,13,3.8,13,10c0,1.5-0.4,2.1-1.4,2.1h-3.5
        		c-0.8,0-1.3-0.6-1.4-1.4c-0.3-3.1-2.3-4.6-6.4-4.6c-4,0-6.4,1.4-6.4,3.9c0,2.6,2.1,3.9,8.4,5.2c8.4,1.6,11.6,4.7,11.6,11
        		c0,6.6-5.1,10.8-13.2,10.8C840.6-35.7,834.6-39.9,834.6-47.2z" />
        <path fill="#FFDF8C" d="M875.3-36.4c-0.8,0-1.4-0.6-1.4-1.4v-47.5c0-0.8,0.6-1.4,1.4-1.4h3.5c0.8,0,1.4,0.6,1.4,1.4v13
        		c0,1.6,0.5,2.3,1.4,2.3c1.5,0,2.1-2.6,8.4-2.6c7.7,0,12.2,5.1,12.2,12.7v22c0,0.8-0.6,1.4-1.4,1.4h-3.5c-0.8,0-1.4-0.6-1.4-1.4
        		v-19.8c0-5.2-2.7-8.6-7.8-8.6c-4.4,0-7.8,3.1-7.8,8.2v20.1c0,0.8-0.6,1.4-1.4,1.4H875.3z" />
        <path fill="#FFDF8C" d="M914.5-41.6c-2.4-3.6-2.6-7.6-2.6-12.5c0-5,0.2-8.9,2.6-12.5c2.4-3.6,6.5-5.9,12-5.9s9.6,2.3,12,5.9
        		c2.4,3.6,2.6,7.6,2.6,12.5c0,5-0.2,8.9-2.6,12.5s-6.5,5.9-12,5.9S917-38,914.5-41.6z M933.1-45.4c1.3-1.9,1.6-4.6,1.6-8.7
        		c0-4.1-0.3-6.8-1.6-8.7c-1.1-1.7-3.3-3.3-6.5-3.3s-5.4,1.6-6.5,3.3c-1.3,1.9-1.6,4.6-1.6,8.7c0,4.1,0.3,6.8,1.6,8.7
        		c1.1,1.7,3.3,3.3,6.5,3.3S932-43.7,933.1-45.4z" />
        <path fill="#FFDF8C" d="M953.9-22.2c-0.8,0-1.4-0.6-1.4-1.4v-46.8c0-0.8,0.6-1.4,1.4-1.4h2.4c0.6,0,0.9,0.1,1,0.5
        		c0.4,1.1,0.9,1.5,1.4,1.5c1.5,0,2.6-2.7,8.3-2.7c5.2,0,9.6,2.2,11.5,6.1c1.5,3.1,2.2,6.9,2.2,12.3c0,5.7-0.6,9.5-2.3,12.4
        		c-2.1,3.8-6.1,6-10.8,6c-4.7,0-6.2-2.3-7.4-2.3c-0.9,0-1.3,0.6-1.3,2.3v12c0,0.8-0.6,1.4-1.4,1.4H953.9z M973-45.3
        		c1.1-2.2,1.3-4.7,1.3-8.8c0-4.1-0.1-6.6-1.3-8.8c-1.2-2.2-3.7-3.3-6.7-3.3c-3,0-5.5,1.1-6.7,3.3c-1.2,2.2-1.3,4.7-1.3,8.8
        		c0,4.1,0.1,6.6,1.3,8.8c1.2,2.2,3.7,3.3,6.7,3.3C969.3-42.1,971.9-43.1,973-45.3z" />
      </g>
      <g>
        <path fill="#FFE696" d="M97.4,94.7l-9,19.9h-8.7l-4.7-11l-4.5,11h-9.1l-9-19.9h9.8l4.7,11.5l4.6-11.5h8.1l5,11.5l4.7-11.5H97.4z" />
        <path fill="#FFE696" d="M114.5,114.9c-4.8,0-8.6-0.9-11.6-2.8c-3-1.9-4.5-4.3-4.5-7.5c0-3,1.4-5.4,4.3-7.4
        		c2.9-1.9,6.8-2.9,11.9-2.9c4.7,0,8.5,0.9,11.5,2.7c3,1.8,4.4,4.3,4.4,7.3c0,3.2-1.5,5.7-4.5,7.6
        		C123.1,113.9,119.2,114.9,114.5,114.9z M114.5,110.4c1.7,0,3-0.4,3.8-1.3c0.8-0.9,1.2-2.5,1.2-4.9c0-3.5-1.6-5.2-4.7-5.2
        		c-3.4,0-5.1,2-5.1,5.9C109.6,108.5,111.2,110.4,114.5,110.4z" />
        <path fill="#FFE696" d="M165,114.6h-11.5l-4.9-7.6h-3.3v7.6h-10.4V94.7h17.6c3.9,0,6.9,0.5,9.1,1.6c2.2,1.1,3.2,2.5,3.2,4.3
        		c0,1.1-0.4,2.2-1.2,3c-0.8,0.9-2.4,1.7-4.8,2.3L165,114.6z M145.3,102.7h4.4c1.3,0,2.4-0.2,3.1-0.5c0.8-0.3,1.2-0.8,1.2-1.4
        		c0-1.3-1.3-1.9-4-1.9h-4.8V102.7z" />
        <path fill="#FFE696" d="M200.4,114.6H189l-5.8-7.5l-3.5,2.9v4.6h-9.8V94.7h9.8v8.2l9.3-8.2h9.3l-8.5,7.3L200.4,114.6z" />
        <path fill="#FFE696" d="M199.7,108.8l9.6-1c0.8,1.6,2.9,2.4,6.2,2.4c2.6,0,3.9-0.4,3.9-1.2c0-0.4-0.3-0.8-0.9-1
        		c-0.6-0.2-1.7-0.4-3.2-0.6c-5.9-0.7-9.7-1.5-11.4-2.6c-1.7-1.1-2.5-2.3-2.5-3.8c0-1.9,1.2-3.4,3.6-4.7c2.4-1.2,5.9-1.9,10.4-1.9
        		c6.9,0,11.4,1.6,13.6,4.9l-8.6,1.5c-0.9-1.3-2.7-2-5.4-2c-2.3,0-3.4,0.4-3.4,1.2c0,0.4,0.3,0.7,0.8,0.9c0.5,0.2,1.5,0.4,3,0.6
        		c4,0.5,6.9,0.9,8.6,1.3c1.7,0.4,3.1,1.1,4.3,2c1.1,0.9,1.7,2,1.7,3.3c0,2-1.4,3.6-4.2,4.9c-2.8,1.3-6.4,1.9-10.9,1.9
        		C206.7,114.9,201.6,112.9,199.7,108.8z" />
        <path fill="#FFE696" d="M262.6,94.7v19.9h-10.4v-7.7h-7.9v7.7h-10.4V94.7h10.4v7.3h7.9v-7.3H262.6z" />
        <path fill="#FFE696" d="M283.1,114.9c-4.8,0-8.6-0.9-11.6-2.8c-3-1.9-4.5-4.3-4.5-7.5c0-3,1.4-5.4,4.3-7.4
        		c2.9-1.9,6.8-2.9,11.9-2.9c4.7,0,8.5,0.9,11.5,2.7c3,1.8,4.4,4.3,4.4,7.3c0,3.2-1.5,5.7-4.5,7.6
        		C291.6,113.9,287.8,114.9,283.1,114.9z M283,110.4c1.7,0,3-0.4,3.8-1.3c0.8-0.9,1.2-2.5,1.2-4.9c0-3.5-1.6-5.2-4.7-5.2
        		c-3.4,0-5.1,2-5.1,5.9C278.1,108.5,279.8,110.4,283,110.4z" />
        <path fill="#FFE696" d="M314,107.3v7.2h-10.5V94.7h14.4c3.6,0,6.3,0.2,8.2,0.7c1.9,0.5,3.4,1.2,4.5,2.2c1.1,1,1.6,2.1,1.6,3.4
        		c0,1.9-1.1,3.4-3.4,4.6c-2.2,1.2-5.2,1.8-9,1.8H314z M313.9,103.1h3.5c3.1,0,4.6-0.7,4.6-2c0-1.2-1.4-1.9-4.2-1.9h-3.8V103.1z" />
      </g>
    </svg>
  );
}
