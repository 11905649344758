import React from "react";
import styled from "styled-components";
import ProjectThumbnail from "./ProjectThumbnail";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import axios from "axios";

import baseUrl from "../constants/baseUrl";

const url = baseUrl.projects;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: center;
  font-family: Druk, sans-serif;
  background: #FFE696;
  @media ${mediaQueries.extraSmallDevices} {
    padding: 20px 50px;
  }
  @media ${mediaQueries.smallDevices} {
    padding: 20px 50px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding: 50px 50px;
  }
  @media ${mediaQueries.largeDevices} {
    padding: 50px 80px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 50px 180px;
  }
`;

const LatestProjectsTitle = styled.div`
  font-family: Druk, sans-serif;
  margin-top: 50px;
  color: #00373e;
  @media ${mediaQueries.extraSmallDevices} {
    margin: 20px 0px;
    font-size: 20px;
  }
  @media ${mediaQueries.smallDevices} {
    margin: 20px 0px;
    font-size: 25px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin: 0px 20px;
    font-size: 28px;
  }
  @media ${mediaQueries.largeDevices} {
    margin: 0px 25px;
    font-size: 30px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin: 0px 40px;
    font-size: 35px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 10px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 10px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-top: 15px;
  }
  @media ${mediaQueries.largeDevices} {
    margin-top: 40px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-top: 40px;
  }
`;
const ListItem = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    flex: 0 0 100%;
  }
  @media ${mediaQueries.smallDevices} {
    flex: 0 0 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    flex: 0 0 33.333333%;
  }
  @media ${mediaQueries.largeDevices} {
    flex: 0 0 33.333333%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex: 0 0 33.333333%;
  }
`;

const IconBox = styled.div`
  text-align: center;
  justify-items: center;
  color: #00373E;
  @media ${mediaQueries.extraSmallDevices} {
   
  }
  @media ${mediaQueries.smallDevices} {

  }
  @media ${mediaQueries.mediumDevices} {
 
  }
  @media ${mediaQueries.largeDevices} {

  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
  
  }
`;

class LatestActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = { project1: {}, project2: {}, project3: {}, isLoad: false };
  }
  componentDidMount() {
    axios.get(`/projects/get/last2`).then((res) => {
      let projects = res.data;
        this.setState({
          project1: projects[0],
          project2: projects[1],
          project3: projects[2],
          isLoad: true,
        });
      });
  }

  render() {
    return (
      <Block>
        {/* <LatestProjectsTitle>Atividade Recente</LatestProjectsTitle> */}
        <IconBox>
          <i className={`arrow down icon large`}></i>
        </IconBox>
        {this.state.isLoad && (
          <ListContainer>
            <ListItem>
              <ProjectThumbnail
                title={this.state.project1.title}
                subtitle={this.state.project1.logline}
                imageSrc={
                  url +
                  this.state.project1.thumbnailSquareUrl
                }
                link={`/projetos/${this.state.project1.title.replace(
                  /\s/g,
                  "_"
                )}`}
              />
            </ListItem>
            <ListItem>
              <ProjectThumbnail
                title={this.state.project2.title}
                subtitle={this.state.project2.logline}
                imageSrc={
                  url +
                  this.state.project2.thumbnailSquareUrl
                }
                link={`/projetos/${this.state.project2.title.replace(
                  /\s/g,
                  "_"
                )}`}
              />
            </ListItem>
            <ListItem>
              <ProjectThumbnail
                title={this.state.project3.title}
                subtitle={this.state.project3.logline}
                imageSrc={
                  url +
                  this.state.project3.thumbnailSquareUrl
                }
                link={`/projetos/${this.state.project3.title.replace(
                  /\s/g,
                  "_"
                )}`}
              />
            </ListItem>
          </ListContainer>
        )}
      </Block>
    );
  }
}

export default LatestActivity;
