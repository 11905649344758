import React from "react";
import styled from "styled-components";
import ProductCard from "./ProductCard";
import { connect } from "react-redux";
import { changeRentingPageCategory } from "../actions/index";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";

const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const ProductCardContainer = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    width: 90%;
    padding: 15px 15px 15px 0px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 90%;
    padding: 15px 15px 15px 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 320px;
    padding: 15px 15px 15px 0px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 30%;
    padding: 15px 15px 15px 0px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 320px;
    padding: 15px 15px 15px 0px;
  }
`;

class RentingPageItemsGrid extends React.Component {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <FlexGrid>
        {this.props.currItems !== undefined &&
          this.props.currItems.map((item) => (
            <ProductCardContainer>
              <ProductCard data={item} />
            </ProductCardContainer>
          ))}
      </FlexGrid>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currItems: state.currItems,
  };
};

export default connect(
  mapStateToProps,
  { changeRentingPageCategory }
)(RentingPageItemsGrid);
