import React from "react";
import AliceCarousel from "react-alice-carousel";
import styled from "styled-components";
import axios from "axios";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";

const ProductMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
  @media ${mediaQueries.extraSmallDevices} {
    width: 90%;
    margin-top: 30px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 90%;
    margin-top: 30px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 90%;
    margin-top: 30px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 70%;
    margin-top: 30px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 60%;
    margin-top: 80px;
  }
  
`;

const ProductMenuTitle = styled.div`
  font-family: Druk, sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    margin-bottom: 20px;
    font-size: 13px;
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    margin-bottom: 20px;
    font-size: 17px;
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-bottom: 20px;
    font-size: 20px;
  }
  @media ${mediaQueries.largeDevices} {
    margin-bottom: 20px;
    font-size: 20px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-bottom: 50px;
    font-size: 25px;
  }
`;

const ProductDetailContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: row;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: row;
  }
`;

const ProductSlideShow = styled.div`
  display: flex;
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 50%;
  }
  @media ${mediaQueries.largeDevices} {
    width: 40%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 45%;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  
  flex-direction: column;
  color: black;
  @media ${mediaQueries.extraSmallDevices} {
    margin-left: 0px;
    margin-bottom: 100px;
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    margin-left: 0px;
    margin-bottom: 100px;
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-left: 0px;
    margin-bottom: 100px;
    width: 45%;
  }
  @media ${mediaQueries.largeDevices} {
    margin-left: 50px;
    margin-bottom: 50px;
    width: 45%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-left: 50px;
    margin-bottom: 50px;
    width: 45%;
  }
`;


const ProductDetailsSubtitle = styled.div`
  font-size: 14px;
  font-family: Druk, sans-serif;
  color: grey;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 12px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 14px;
  }
`;

const ProductDetailsText = styled.div`
  font-family: 'DM Sans', sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
    margin-bottom: 18px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 14px;
    margin-bottom: 18px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 14px;
    margin-bottom: 18px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 14px;
    margin-bottom: 18px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

class RentingProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, product: {}, images: [] };
    this.photosUrls = [];
    this.images = [];
  }

  componentDidMount() {
    axios.get(`/items/${this.props.match.params.id}`).then((res) => {
      let product = res.data[0];

      // Photos URLS
      let numberOfPhotos = product.photoUrls;
      for (let i = 0; i < numberOfPhotos; i++) {
        let imageURL =
          `${process.env.PUBLIC_URL}/` +
          "./images/item_database/" +
          product.reference +
          `_${i + 1}.jpg`;
        this.photosUrls.push(imageURL);
      }

      // Photos Elements
      let images = this.photosUrls.map((url) => (
        <img src={url} onDragStart={this.handleDragStart} width={"100%"} />
      ));

      this.setState({ isLoading: false, product: product, images: images });
    });
  }

  handleDragStart = (e) => e.preventDefault();

  renderProductDetails = ({
    reference,
    stockQuantity,
    description,
    dimensions,
    percentage,
    fullprice,
    colors,
  }) => {
    return (
      <ProductDetails>
        <ProductDetailsSubtitle>Referência:</ProductDetailsSubtitle>
        <ProductDetailsText>{reference}</ProductDetailsText>
        <ProductDetailsSubtitle>Descrição:</ProductDetailsSubtitle>
        <ProductDetailsText>{description}</ProductDetailsText>
        <ProductDetailsSubtitle>Cores:</ProductDetailsSubtitle>
        <ProductDetailsText>
          {colors.map((color,i) => {if(i===colors.length-1){
            return color
          }else{
            return color + ", "
          }})}
        </ProductDetailsText>
        <ProductDetailsSubtitle>Dimensões:</ProductDetailsSubtitle>
        <ProductDetailsText>{dimensions}</ProductDetailsText>
        <ProductDetailsSubtitle>Stock:</ProductDetailsSubtitle>
        <ProductDetailsText>{stockQuantity}</ProductDetailsText>
        <ProductDetailsSubtitle>Preço/Dia:</ProductDetailsSubtitle>
        <ProductDetailsText>{(percentage * fullprice).toFixed(2)} €</ProductDetailsText>
      </ProductDetails>
    );
  };

  render() {
    console.log(this.state.product);
    return (
      <div>
        {!this.state.isLoading && (
          <ProductMenu>
            <ProductMenuTitle>{this.state.product.name}</ProductMenuTitle>
            <ProductDetailContainer>
              
              <ProductSlideShow>
                <AliceCarousel mouseTracking items={this.state.images} />
              </ProductSlideShow>
              {this.renderProductDetails(this.state.product)}
            </ProductDetailContainer>
          </ProductMenu>
        )}
      </div>
    );
  }
}

export default RentingProductPage;
