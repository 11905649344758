import React from "react";
import sections from "../assets/content/NavBarData";
import {
  changeRentingPageCategory,
  fetchItemsByCategory,
} from "../actions/index";
import { connect } from "react-redux";
import styled from "styled-components";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";

const subsections = sections[2].sections;

const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: row;
    overflow: auto;
    white-space: nowrap;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: row;
    overflow: auto;
    white-space: nowrap;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: row;
    overflow: auto;
    white-space: nowrap;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: column;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  &:hover {
    color: #eacab1;
    transition: linear 100ms;
    background: white;
    cursor: pointer;
  }
  @media ${mediaQueries.extraSmallDevices} {
    padding: 10px 5px 10px 0px;
    margin: 0px 60px 0px 10px;
    background: white;
    font-size: 12px;
    color: ${(props) => (props.active ? `black` : `grey`)};
  }
  @media ${mediaQueries.smallDevices} {
    padding: 8px 5px 8px 0px;
    margin: 0px 60px 0px 10px;
    background: white;
    font-size: 14px;
    color: ${(props) => (props.active ? `black` : `grey`)};
  }
  @media ${mediaQueries.mediumDevices} {
    padding: 10px 5px 15px 0px;
    margin: 0px 60px 0px 10px;
    background: white;
    font-size: 16px;
    color: ${(props) => (props.active ? `black` : `grey`)};
  }
  @media ${mediaQueries.largeDevices} {
    padding: 0px 0px 0px 5px;
    margin: 0px 0px 5px 0px;
    background: ${(props) => (props.active ? `rgb(200,200,200)` : `white`)};
    color: black;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 0px 0px 0px 5px;
    margin: 0px 0px 5px 0px;
    background: ${(props) => (props.active ? `rgb(200,200,200)` : `white`)};
    color: black;
  }
`;

class RentingPageSelectionMenu extends React.Component {
  handleItemClick = (e, { name }) => {
    this.props.changeRentingPageCategory(name);
    this.props.fetchItemsByCategory(name);
  };
  render() {
    return (
      <Menu>
        <Item
          name={"0"}
          active={this.props.activeCategory === "0"}
          onClick={(e) => this.handleItemClick(e, { name: "0" })}
        >
          Todos
        </Item>
        {/* Render Sections */}
        {subsections.map(({ title, key }) => (
          <Item
            name={key}
            active={this.props.activeCategory === key}
            onClick={(e) => this.handleItemClick(e, { name: key })}
          >
            {title}
          </Item>
        ))}
      </Menu>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { activeCategory: state.selectedRentingCategory };
};

export default connect(
  mapStateToProps,
  { changeRentingPageCategory, fetchItemsByCategory }
)(RentingPageSelectionMenu);
