import React, { useEffect, useState } from "react";

const Fade = ({ show, children, isIn, isOut }) => {
  const [shouldRender, setRender] = useState(show);
  let fadeIn = "fadeIn";
  let fadeOut = "fadeOut";
  if (isIn !== undefined) {
    fadeIn = isIn ? "fadeIn" : "";
  }
  if (isOut !== undefined) {
    fadeOut = isOut ? "fadeOut" : "";
  }

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    shouldRender && (
      <div
        style={{ animation: `${show ? fadeIn : fadeOut} 1000ms` }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

export default Fade;
