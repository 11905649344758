import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  changeRentingPageCategory,
  fetchItemsByCategory,
} from "../actions/index";
import RentingPageSearchBar from "./RentingPageSearchBar";
import RentingPageSelectionMenu from "./RentingPageSelectionMenu";
import RentingPageItemsGrid from "./RentingPageItemsGrid";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import {
  Tablet,
  LaptopDesktop,
  Mobile,
} from "../components/ReactResponsiveComponents";

const Background = styled.div`
  background: white;
  z-index: 100;
  padding-bottom: 100px;
  @media ${mediaQueries.extraSmallDevices} {
    padding-bottom: 50px;
  }
  @media ${mediaQueries.smallDevices} {
    padding-bottom: 50px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding-bottom: 100px;
  }
  @media ${mediaQueries.largeDevices} {
    padding-bottom: 100px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding-bottom: 100px;
  }
`;

const FixedContainer = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  z-index: 100;
  @media ${mediaQueries.extraSmallDevices} {
    top: 10px;
  }
  @media ${mediaQueries.smallDevices} {
    top: 10px;
  }
  @media ${mediaQueries.mediumDevices} {
    top: 0;
  }
  @media ${mediaQueries.largeDevices} {
    top: 0;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    top: 0;
  }
`;

const FixedTitle = styled.div`
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0px;
  padding: 20px 10px;
  font-family: "Druk", sans-serif;
  font-size: 25px;
  color: black;
  @media ${mediaQueries.extraSmallDevices} {
    padding: 15px 20px;
    font-size: 20px;
  }
  @media ${mediaQueries.smallDevices} {
    padding: 15px 20px;
    font-size: 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding: 15px 20px;
    font-size: 20px;
  }
  @media ${mediaQueries.largeDevices} {
    padding: 20px 10px;
    font-size: 25px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 20px 20px;
    font-size: 30px;
  }
`;

const SelectionMenuContainer = styled.div`
  font-family: "DM Sans", sans-serif;
  background: white;
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 2px;
    width: 100%;
    height: 40px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 2px;
    width: 100%;
    height: 40px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-top: 2px;
    width: 100%;
    height: 80px;
  }
  @media ${mediaQueries.largeDevices} {
    margin-top: 0px;
    width: 300px;
    padding: 120px 50px 0px 10px;
    font-size: 16px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-top: 0px;
    width: 350px;
    padding: 150px 50px 0px 20px;
    font-size: 18px;
  }
`;

const SearchBarContainer = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
    padding: 0px 20px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
    padding: 0px 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 100%;
    padding: 0px 20px;
  }
  @media ${mediaQueries.largeDevices} {
    width: calc(100% - 300px);
    padding: 0px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: calc(100% - 350px);
    padding: 0px;
  }
`;

const SearchBarFader = styled.div`
  width: 100%;
  height: 30px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  @media ${mediaQueries.extraSmallDevices} {
    height: 20px;
  }
  @media ${mediaQueries.smallDevices} {
    height: 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    height: 20px;
  }
  @media ${mediaQueries.largeDevices} {
    height: 30px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    height: 30px;
  }
`;

const SearchBarPadding = styled.div`
  width: 100%;
  background: white;
  @media ${mediaQueries.extraSmallDevices} {
    height: 40px;
  }
  @media ${mediaQueries.smallDevices} {
    height: 50px;
  }
  @media ${mediaQueries.mediumDevices} {
    height: 50px;
  }
  @media ${mediaQueries.largeDevices} {
    height: 70px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    height: 100px;
  }
`;

const Grid = styled.div`
  padding-right: 5vw;
  z-index: 50;
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
    padding: 0px 20px;
    margin-top: 140px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
    padding: 0px 40px;
    margin-top: 140px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 100%;
    padding: 0px 50px;
    margin-top: 140px;
  }
  @media ${mediaQueries.largeDevices} {
    width: calc(100% - 300px);
    margin-left: 250px;
    margin-top: 70px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: calc(100% - 350px);
    margin-left: 350px;
    margin-top: 100px;
  }
`;

class RentingPage extends React.Component {
  componentDidMount() {
    if (this.props.match.params.id != undefined) {
      const category = this.props.match.params.id;
      this.props.changeRentingPageCategory(category); // update redux state from menu
      this.props.fetchItemsByCategory(category);
    } else {
      const category = "0";
      this.props.changeRentingPageCategory(category); // update redux state from menu
      this.props.fetchItemsByCategory(category);
    }
  }

  LaptopDesktopVersion = () => {
    return (
      <LaptopDesktop>
        <FixedTitle>Aluguer</FixedTitle>
        <FixedContainer>
          <SelectionMenuContainer>
            <RentingPageSelectionMenu />
          </SelectionMenuContainer>
          <SearchBarContainer>
            <SearchBarPadding />
            <RentingPageSearchBar />
            <SearchBarFader />
          </SearchBarContainer>
        </FixedContainer>
        <Grid>
          <RentingPageItemsGrid />
        </Grid>
      </LaptopDesktop>
    );
  };

  TabletVersion = () => {
    return (
      <Tablet>
        <FixedContainer>
          <SearchBarContainer>
            <FixedTitle>Aluguer</FixedTitle>
            <SearchBarPadding />
            <RentingPageSearchBar />
            <SelectionMenuContainer>
              <RentingPageSelectionMenu />
            </SelectionMenuContainer>
            <SearchBarFader />
          </SearchBarContainer>
        </FixedContainer>
        <Grid>
          <RentingPageItemsGrid />
        </Grid>
      </Tablet>
    );
  };

  MobileVersion = () => {
    return (
      <Mobile>
        <FixedContainer>
          <SearchBarContainer>
            <FixedTitle>Aluguer</FixedTitle>
            <SearchBarPadding />
            <RentingPageSearchBar />
            <SelectionMenuContainer>
              <RentingPageSelectionMenu />
            </SelectionMenuContainer>
            <SearchBarFader />
          </SearchBarContainer>
        </FixedContainer>
        <Grid>
          <RentingPageItemsGrid />
        </Grid>
      </Mobile>
    );
  };

  render() {
    return (
      <Background>

        {this.LaptopDesktopVersion()}
        {this.MobileVersion()}
        {this.TabletVersion()}
      </Background>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedRentingCategory: state.selectedRentingCategory,
    currentItems: state.currItems,
  };
};

export default connect(
  mapStateToProps,
  { changeRentingPageCategory, fetchItemsByCategory }
)(RentingPage);
