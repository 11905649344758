export default [
    "images/PWK_Oficina-5.png",
    "images/PWK_Workshop_Session01_298.png",
    "images/PWK_QuadroAmbiente.png",
    "images/OurArtDirector.png",
    "./images/PWK_Workshop_Session01_242.png",
    "./images/PWORKSHOP_CaisAveiro_25.png",
    "./images/PWK_aluguer.png",
    "./images/PWK_Corno.png",
    "./images/_MG_0393.png",
    "./images/PWORKSHOP_CaisAveiro_08.png",
    "./images/_MG_0292.png",
    "./images/3d-print.png",
    "images/PWK_Oficina-4.png",
    "images/PWK_atelie.png",
    "images/PWK_tecnicas.png",
    "./images/PWK_Workshop_Session01_267.png",
    "./images/PWK_Workshop_Session01_322.png",
    "./images/PWK_Oficina-28.png",
    "./images/B003-1.png",
    "./images/B003-3.png",
    "./images/PWORKSHOP_CaisAveiro_58.png",
    "images/cais-1.png",
    "images/cais-2.png",
    "images/cais-3.png",
    "images/cais-4.png",
    "images/cais-6.png",
    "images/cais-final.png",
    "images/Cubos-2.png",
    "images/Cubos-1.png",
    "images/Cubos-3.png",
    "images/Bolas-1.png",
    "images/Bolas-2.png",
    "images/Bolas-3.png",
    "images/Otto-1.png",
    "images/Otto-2.png",
    "images/Otto-3.png",
    "images/PW_ARMA_001.png",
    "images/PW_ARMA_004.png",
    "images/PW_ARMA_005.png",
    "images/PW_ARMA_002.png",
    "images/PW_ARMA_003.png",
    "images/Maos-01.png",
    "images/Maos-02.png",
    "images/Maos-03.png",
    "images/Maos-04.png",
    "images/Maos-05.png",
    "images/Memoria-01.png",
    "images/Memoria-02.png",
    "images/Memoria-03.png",
    "images/Blu19-04.png",
    "images/Blu19-01.png",
    "images/Blu19-03.png",
    "images/Blu19-06.png"
  
  ];