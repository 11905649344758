export default (array, currentIndex, step, direction = "right") => {
  if (direction === "right") {
    if (currentIndex + step < array.length) {
      return { value: array[currentIndex + step], index: currentIndex + step };
    } else {
      return {
        value: array[currentIndex + step - array.length],
        index: currentIndex + step - array.length
      };
    }
  } else if (direction === "left") {
    if (currentIndex - step >= 0) {
      return { value: array[currentIndex - step], index: currentIndex - step };
    } else {
      return {
        value: array[array.length - (currentIndex + step)],
        index: array.length - (currentIndex + step)
      };
    }
  }
};
