export default [
  {
    title: "Design",
    description:
      "Specialists in creating rich fictional worlds, our Design Studio is inspired by projects at any stage of development.  From blockbuster films and TV series, to public and private art sculptures, digital games, and immersive visitor experiences, we love to collaborate with our clients in service to their vision.",
    includes: [
      "Film & TV",
      "Location-based experiences",
      "Digital Interactives",
      "Public art sculptures",
      "Private commissions",
      "Collectibles",
    ],
    imageURL: "./images/PWK_Workshop_Session01_242.png",
  },
  {
    title: "Build",
    description:
      "With seventeen manufacturing departments under one roof, our workshop floor is home to an energetic creative team who work together on projects of any size and scale. From the epic to the intimate, we combine traditional handcraft techniques with innovative new technologies to make our clients’ vision a reality.",
    includes: [
      "Film & TV",
      "Location-based experiences",
      "Digital Interactives",
      "Public art sculptures",
      "Private commissions",
      "Collectibles",
    ],
    imageURL: "images/PW_ARMA_005.png",
  },
];

