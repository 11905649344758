export default {
  phone: {
    title: "ARTE",
    body: "daniel@burilworkshop.com",
    phone: "933 650 062",
    description: "Daniel L. Machado, Departamento de Arte"
  },
  email: {
    title: "LOGÍSTICA",
    body: "diogo@burilworkshop.com",
    phone: "910 417 398",
    description: "Diogo V. Machado, Produção"
  },
  address1: {
    title: "PORTO",
    body: "Avenida Américo Teixeira, Nº 264, Parque Industrial Tepi, Armazém 4",
    description: "4795-160 Rebordões, Porto"
  },
  address2: {
    title: "LISBOA",
    body: "EN 10, Km 135.2, Armazém A27",
    description: "2690-425 - Santa Iria de Azoia"
  }
};
