import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { isSiteLoaded } from "../actions/index";
import Fade from "../utilsComponents/FadeMountUnmount";
import Logo from "./Logo";
import urls from "../assets/content/loadingImagesData";

const { useState, useEffect, useRef } = React;



const LoadScreen = styled.div`
  position: absolute;
  display: flex;
  background: white;
  width: 100%;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;
`;

const LogoWrapper = styled.div`
  width: 40%;
  text-align: center;
`;

const SpinnerWrapper = styled.div`
  color: black;
`;

const LoadSitePage = props => {
  const [loading, setLoading] = useState(true);
  const counter = useRef(0);
  
  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= urls.length) {
      props.isSiteLoaded(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  
  return (
    <React.Fragment>
      <Fade show={loading} isIn={false}>
        <LoadScreen>
          <LogoWrapper>
            <Logo width={"100%"} height={"54%"} />
          </LogoWrapper>
          <SpinnerWrapper>
            <i class="notched circle loading icon big"></i>
          </SpinnerWrapper>
        </LoadScreen>
      </Fade>
      <div style={{ display: "none" }}>
        {urls.map(url => (
          <img key={url} src={url} onLoad={imageLoaded} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default connect(null, { isSiteLoaded })(LoadSitePage);
