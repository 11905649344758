import React from "react";
import styled from "styled-components";
import breakpoints from "../constants/breakpoints";
import mediaQueries from "../constants/mediaQueries";
import navigateOnArray from "../utils/NavigateOnArray";
import CrossfadeImage from "react-crossfade-image";
import { LargeDevice, Mobile } from "../components/ReactResponsiveComponents";
import withSizes from "react-sizes";

const NewsContainer = styled.div`
  width: 100%;
  display: flex;
  background: rgb(40, 40, 40);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${mediaQueries.extraSmallDevices} {
    /* padding: 30px 20px 60px 20px; */
  }
  @media ${mediaQueries.smallDevices} {
    /* padding: 30px 20px 60px 20px; */
  }
  @media ${mediaQueries.mediumDevices} {
    /* padding: 60px 10px 60px 10px; */
  }
  @media ${mediaQueries.largeDevices} {
    /* padding: 60px 40px 60px 40px; */
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    /* padding: 40px 100px 60px 40px; */
  }
`;

const ComponentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-radius: 2px;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: row;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: row;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 18px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 75%;
  }
  @media ${mediaQueries.largeDevices} {
    width: 60%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 60%;
  }
`;

const FunctionalContainer = styled.div`
  background: #0071BC;
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 25%;
  }
  @media ${mediaQueries.largeDevices} {
    width: 40%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 40%;
  }
`;

const Text = styled.div`
  color: white;
  font-family: "DM Sans", sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 10px;
    font-size: 14px;
    padding: 0 20px;
    height: 40px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 10px;
    font-size: 14px;
    padding: 0 20px;
    height: 40px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-top: 25px;
    font-size: 14px;
    padding: 0 15px;
  }
  @media ${mediaQueries.largeDevices} {
    margin-top: 50px;
    font-size: 16px;
    padding: 0 20px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-top: 50px;
    font-size: 16px;
    padding: 0 20px;
  }
`;

const GUI = styled.div`
  display: flex;
  width: 100%;
  background: #0071BC;
  @media ${mediaQueries.extraSmallDevices} {
    padding: 10px 20px;
  }
  @media ${mediaQueries.smallDevices} {
    padding: 10px 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding: 10px 10px;
  }
  @media ${mediaQueries.largeDevices} {
    padding: 10px 10px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 10px 10px;
  }
`;

const IconBox = styled.div`
  background: rgb(30, 30, 30);
  color: white;
  margin-left: 2px;
  padding: 10px;
  &:hover {
    transition: 100ms;
    background: rgb(50, 50, 50);
    cursor: pointer;
  }
`;

const CrossFadeContainer = styled.div`
  width: 100%;
`;

const baseUrl = `${process.env.PUBLIC_URL}/` + "images/project_database/";

class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0 };
    this.timeSwitch();
    this.isPreloaded = false;
  }

  // Pre-Load News Images
  componentDidUpdate() {
    if (!this.props.isLoading && !this.isPreloaded) {
      let urls;
      urls = this.props.data.map((item) => item.imageUrl);
        console.log(urls);
      urls.forEach((url) => {
        const img = new Image();
        img.src = baseUrl + url;
        window[url] = img;
      });

      this.isPreloaded = true;
    }
  }

  timeSwitch() {
    this.interval = setInterval(() => {
      if (!this.props.isLoading) {
        let index = navigateOnArray(
          this.props.data,
          this.state.currentIndex,
          1,
          "right"
        ).index;
        this.setState({ currentIndex: index });
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  switchIndex(direction) {
    clearInterval(this.interval);
    let index = navigateOnArray(
      this.props.data,
      this.state.currentIndex,
      1,
      direction
    ).index;
    this.setState({ currentIndex: index });
  }

  renderContent() {
    let currentNew = this.props.data[this.state.currentIndex];
    let { summary, imageUrl } = currentNew;
    let imageURL = baseUrl + imageUrl;
    return (
      <>
        <ImageContainer>
          <CrossFadeContainer>
            <CrossfadeImage
              src={imageURL}
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "none",
                maxHeight: "none",
              }}
            />
          </CrossFadeContainer>
        </ImageContainer>
        <FunctionalContainer>
          <LargeDevice>
            <GUI>
              <IconBox onClick={() => this.switchIndex("left")}>
                <i className={`angle left icon large`}></i>
              </IconBox>
              <IconBox onClick={() => this.switchIndex("right")}>
                <i className={`angle right icon large`}></i>
              </IconBox>
            </GUI>
            <Text>{summary}</Text>
          </LargeDevice>
          <Mobile>
            <Text>{summary}</Text>
            <GUI>
              <IconBox onClick={() => this.switchIndex("left")}>
                <i className={`angle left icon large`}></i>
              </IconBox>
              <IconBox onClick={() => this.switchIndex("right")}>
                <i className={`angle right icon large`}></i>
              </IconBox>
            </GUI>
          </Mobile>
        </FunctionalContainer>
      </>
    );
  }

  render() {
    return (
      <NewsContainer>
        <ComponentContainer>
          {!this.props.isLoading && <>{this.renderContent()}</>}
        </ComponentContainer>
      </NewsContainer>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < breakpoints.mediumDevices,
});

export default withSizes(mapSizesToProps)(ImageSlider);
