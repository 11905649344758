import React from "react";
import styled from "styled-components";
import axios from "axios";
import ProjectThumbnail from "./ProjectThumbnail";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import Fade from 'react-reveal/Fade';


import baseUrl from "../constants/baseUrl";

const url = baseUrl.projects;

const Block = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: flex-start;
  font-family: Druk, sans-serif;
  background: white;
  @media ${mediaQueries.extraSmallDevices} {
    padding: 30px 25px;

  }
  @media ${mediaQueries.smallDevices} {
    padding: 40px 25px;

  }
  @media ${mediaQueries.mediumDevices} {
    padding: 50px 50px 100px 50px;

  }
  @media ${mediaQueries.largeDevices} {
    padding: 50px 50px 100px 50px;

  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 50px 50px 100px 50px;
  }
`;

const BlockTitle = styled.div`
  font-size: 25px;
  /* border-bottom: 2px black solid; */
  color: #171718;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin: 0px 20px;
    font-size: 18px;
  }
  @media ${mediaQueries.largeDevices} {
    margin: 0px 25px;
    font-size: 20px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin: 0px 40px;
    font-size: 22px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* background: pink; */
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 10px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 15px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-top: 20px;
  }
  @media ${mediaQueries.largeDevices} {
    margin-top: 20px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-top: 20px;
  }
`;

const ListItem = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    flex: 0 0 100%;
  }
  @media ${mediaQueries.smallDevices} {
    flex: 0 0 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    flex: 0 0 33.333333%;
  }
  @media ${mediaQueries.largeDevices} {
    flex: 0 0 33.333333%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex: 0 0 33.333333%;
  }
`;

const SubBlock = styled.div`
  display: flex;
  z-index: 100;
  width: 100%;
  color: white;
  border-bottom: 2px #00373E solid;
  padding-bottom: 5px;
  justify-content: space-between;
  @media ${mediaQueries.extraSmallDevices} {
  }
  @media ${mediaQueries.smallDevices} {
  }
  @media ${mediaQueries.mediumDevices} {
  }
  @media ${mediaQueries.largeDevices} {
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
  }
`;


class WorksPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, projects: [] };
  }


  componentDidMount() {
    axios.get(`/projects`).then((res) => {
      let uploadedProjects = res.data;
      uploadedProjects = uploadedProjects.filter( (item) => item.enable===true);
      this.setState({ isLoading: false, projects: uploadedProjects });
    });
  }
  // process.env.PUBLIC_URL + "./images/project_database/" 
  render() {
    console.log(process.env.NODE_ENV);
    return (
      <Fade>
        <Block>
          <SubBlock>
            <BlockTitle>PROJETOS</BlockTitle>
            <BlockTitle>@BW</BlockTitle>
          </SubBlock>
          <ListContainer>
            {this.state.projects.map((item) => (
              <ListItem>
                <ProjectThumbnail
                  title={item.title}
                  subtitle={item.logline}
                  imageSrc={url + item.thumbnailWideUrl}
                  link={`/projetos/${item.title.replace(/\s/g, '_')}`}
                />
              </ListItem>
            ))}
          </ListContainer>
        </Block>
      </Fade>
    );
  }
}

export default WorksPage;
