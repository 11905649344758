import React, { useState, useEffect } from "react";
import NavigateOnArray from "../utils/NavigateOnArray";
import CrossfadeImage from "react-crossfade-image";

// props => 
// animationDuration = image fade duration
// transitionDuration = duration to change image
// content = array of objects with imageURL property


const ImageFader = props => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      let newIndex = NavigateOnArray(props.content, index, 1, "right").index;
      setIndex(newIndex);
    }, props.transitionDuration);
    return () => {
      clearTimeout(timer1);
    };
  });

  return (
    <CrossfadeImage
      duration={props.animationDuration}
      src={props.content[index]}
      style={props.style}
    />
  );
};

export default ImageFader;
