import React from "react";
import styled from "styled-components";
import InfoBlock from "./InfoBlock";
import axios from "axios";
import ContactUs from "./ContactUs";
import Cover from "./Cover";
import Fade from 'react-reveal/Fade';
import baseUrl from "../constants/baseUrl";


var Scroll = require("react-scroll");
var Element = Scroll.Element;
var scroller = Scroll.scroller;

const url = baseUrl.services;


const Background = styled.div`
  /* background: linear-gradient(
    0deg,
    rgba(6, 48, 53, 1) 0%,
    rgba(226, 195, 171, 1) 0%,
    rgba(255, 255, 255, 0) 70%
  ); */
`;

const InfoBlockContainer = styled.div``;

class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, currentService: [] };
    this.scrolled = false;
  }

  componentDidMount() {
    this.scrollSection = this.props.key;
    axios.get(`/services/${this.props.match.params.id}`).then(async (res) => {
      const service = res.data[0];
      await this.cacheImages(service.coverPhotos);
      this.setState({ currentService: service });
      this.setState({ isLoading: false });
    });
  }

  componentDidUpdate() {
    if (this.scrolled === false) {
      scroller.scrollTo("myScrollToElement", {
        duration: 1500,
        delay: 100,
        smooth: true,
        containerId: "ContainerElementID",
        offset: 50,
      });
      this.scrolled = true;
    }
  }

  invertDirection(direction) {
    if (direction === "left") {
      return "right";
    } else {
      return "left";
    }
  }

  renderInfoBlocks = (services) => {
    let direction = "right";
    return services.map((service) => {
      direction = this.invertDirection(direction);
      const { title, description, includes, key, button } = service;

      const imageURL = url + service.imageName;

      if (this.scrollSection !== undefined) {
        // If scroll To element its unable
        if (this.scrollSection === key) {
          console.log("renderizou");
          return (
            <Element name="myScrollToElement">
              <InfoBlock
                title={title}
                description={description}
                includes={includes}
                imageURL={imageURL}
                direction={direction}
                button={button}
                key={title}
              />
            </Element>
          );
        }
      }
      return (
        <InfoBlock
          title={title}
          description={description}
          includes={includes}
          imageURL={imageURL}
          direction={direction}
          key={title}
          button={button}
        />
      );
    });
  };

  cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = url + src;
        img.onload = resolve();
        img.onerror = reject();
      }); 
    });
    let a = await Promise.all(promises);
    return a;
  };

  render() {
    return (
      <Fade>
        {!this.state.isLoading && (
          <Background>
            <Cover
              imagesArray={this.state.currentService.coverPhotos}
              title={this.state.currentService.title}
              description={this.state.currentService.description}
            />

            <InfoBlockContainer>
              {this.renderInfoBlocks(this.state.currentService.subservices)}
            </InfoBlockContainer>
            <ContactUs />
          </Background>
        )}
      </Fade>
    );
  }
}

export default ServicePage;
