import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import { LargeDevice, Mobile } from "../components/ReactResponsiveComponents";


let phone = "933650062 / 910417398";

const adress1 = {
  street: "Estrada Nacional 10, Km 135.2, Armazém A27",
  postal: "2690-425",
  location: "Santa Iria de Azoia",
  city: "Lisboa",
};

const adress2 = {
  street: "Avenida Américo Teixeira, Nº 264, Parque Industrial Tepi, Armazém 4",
  postal: "4795-160",
  location: "Rebordães",
  city: "Porto",
};

const socialMedia =
  "Acompanhe os nossos últimos projetos e produtos nas redes.";
const callToAction =
  "Contacte-nos, se está à procura de uma equipa de arte para fazer o design ou construção do seu próximo projeto.";

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: flex-start;
  justify-content: space-between;
  background: #00373e;
  font-family: Druk, sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    padding: 50px 50px 130px 50px;
  }
  @media ${mediaQueries.smallDevices} {
    padding: 50px 50px 120px 50px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding: 50px 50px 110px 50px;
  }
  @media ${mediaQueries.largeDevices} {
    padding: 50px 100px 110px 50px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 70px 10vw 70px 10vw;
  }
  cursor: default;
`;

const BlockTitle = styled.div`
  border-bottom: 2px black solid;
  color: white;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 20px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 22px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 19px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 25px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 27px;
  }
  &:hover{
    text-decoration: none;
    color: grey;
  }
`;

const BlockInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
    margin-top: 30px;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
    margin-top: 30px;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: row;
    margin-top: 50px;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: row;
    margin-top: 50px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: row;
    margin-top: 50px;
  }
`;

const BlockItem = styled.div`
  margin: 15px 0px;
`;

const BlockItemH1 = styled.div`
  color: #eacab1;
  max-width: 380px;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 12px;
    width: 60vw;
    margin-bottom: 15px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 13px;
    width: 60vw;
    margin-bottom: 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 14px;
    width: 25vw;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 15px;
    width: 25vw;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 17px;
    width: 30vw;
  }
`;

const BlockItemH2 = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 13px;
    text-decoration: underline;

  }
  @media ${mediaQueries.smallDevices} {
    font-size: 13px;
    text-decoration: underline;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 14px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 16px;
  }
`;

const BlockItemH3 = styled.div`
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 13px;
    text-decoration: underline;

  }
  @media ${mediaQueries.smallDevices} {
    font-size: 13px;
    text-decoration: underline;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 14px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 16px;
  }
`;

const BlockItemP = styled.div`
  font-size: 12px;
  margin-top: 10px;
  font-family: "DM Sans", sans-serif;

  @media ${mediaQueries.extraSmallDevices} {
    font-size: 10px;
    max-width: 60vw;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 10px;
    max-width: 60vw;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 10px;
    max-width: 170px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 12px;
    max-width: 200px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 16px;
    max-width: 350px;
  }
`;

const MarginIcons = styled.div`
  margin-top: 15px;
`;


const FollowUsIcons = ({ size }) => {
  return (
    <MarginIcons>
      <a href="https://www.facebook.com/pawkworkshop" target="_blank">
        <i className={`facebook icon ${size} inverted`}></i>
      </a>
      <a href="https://www.instagram.com/pawkworkshop" target="_blank">
        <i className={`instagram icon ${size} inverted`}></i>
      </a>
    </MarginIcons>
  );
};

const ContactUs = ({ text, sections }) => {
  const [open, setOpen] = useState(false);
  const Contacts = () => {
    return (
      <>
        <BlockItem>
          <BlockItemH2>LISBOA</BlockItemH2>
          <BlockItemP>{adress1.street}</BlockItemP>
          <BlockItemP>{adress1.city}</BlockItemP>
          <BlockItemP>{adress1.postal + " - " + adress1.location}</BlockItemP>
          <BlockItemH3>PORTO</BlockItemH3>
          <BlockItemP>{adress2.street}</BlockItemP>
          <BlockItemP>{adress2.postal + " - " + adress2.location}</BlockItemP>
          <BlockItemP>{adress2.city}</BlockItemP>
        </BlockItem>
        <BlockItem>
          <BlockItemH2>Siga-nos</BlockItemH2>
          <BlockItemP>{socialMedia}</BlockItemP>
          <FollowUsIcons size={"large"} />
          <BlockItemH3>Telefone</BlockItemH3>
          <BlockItemP>{phone}</BlockItemP>

        </BlockItem>
      </>
    );
  };

  return (
    <Block>
      <BlockTitle as={Link} to={'/contacts'}>Entrar em contacto</BlockTitle>
      <BlockInfo>
        
        <BlockItem>
          <BlockItemH1>{callToAction}</BlockItemH1>
        </BlockItem>
        
        <LargeDevice>
          <Contacts />
        </LargeDevice>
        
        <Mobile>
            <Contacts />
        </Mobile>
      </BlockInfo>
    </Block>
  );
};

export default ContactUs;
