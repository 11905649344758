import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import { LargeDevice, Mobile } from "../components/ReactResponsiveComponents";
import axios from "axios";
import ContactUs from "./ContactUs";
import { DoubleBlockImage, BlockImage3, BlockCentered, HeaderMargin, ProjectTitle } from "./BaseComponents";
import baseUrl from "../constants/baseUrl";


const url = baseUrl.workshop;

const OurWorkshopPageContainer = styled.div`
  width: 100%;
  /* background: green; */
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  @media ${mediaQueries.extraSmallDevices} {
    width: 80%;
  }
  @media ${mediaQueries.smallDevices} {
    width: 80%;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 90%;
  }
  @media ${mediaQueries.largeDevices} {
    width: 90%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 75%;
  }
`;

const SectionContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${mediaQueries.smallDevices} {
  }
  @media ${mediaQueries.mediumDevices} {
    margin-bottom: 0px;
  }
  @media ${mediaQueries.largeDevices} {
    margin-bottom: 0px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-bottom: 0px;
  }
`;

const SectionTextContainer = styled.div`
  width: 90%;
  margin-right: 10px;
  @media ${mediaQueries.extraSmallDevices} {
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: 20px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 40%;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: 10px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 47%;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: 10px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 47%;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: 10px;
  }
`;

const SectionTitle = styled.div`
  padding: 10px;
  font-family: "Druk", sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 26px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 26px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 22px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 27px;
    margin-bottom: 30px;
  }
`;

const SectionImageContainer = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
    border: 1px solid black;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
    border: 1px solid black;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    border: 0px solid black;
    width: 50%;
    padding: 10px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 50%;
    padding: 10px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 50%;
    padding: 10px;
  }
`;

const Description = styled.div`
  padding: 10px;
  margin-top: ${(props) => (props.spaceBetween ? "10px" : "0px")};
  font-family: "DM Sans", sans-serif;
  @media ${mediaQueries.smallDevices} {
    font-size: 15px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 16px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 24px;
  }
`;

const Gap = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    height: 50px;
  }
  @media ${mediaQueries.smallDevices} {
    height: 50px;
  }
  @media ${mediaQueries.mediumDevices} {
    height: 100px;
  }
  @media ${mediaQueries.largeDevices} {
    height: 100px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    height: 100px;
  }
`;

class WorkshopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { complementary: {}, isLoad: false };
  }

  componentDidMount() {
    axios.get(`/complementaries/${this.props.match.params.id}`).then((res) => {
      const complementary = res.data[0];
      this.setState({ complementary: complementary, isLoad: true });
      console.log(complementary);
    });
    console.log(url);
  }

  renderSection = ({ title, imgURL, paragraphs, direction, spaceBetween }) => {
    if (direction === "right") {
      return (
        <Fade>
          <SectionContainer>
            <SectionImageContainer>
              <img
                width={"100%"}
                src={url + imgURL}
                
                
              ></img>
            </SectionImageContainer>
            <SectionTextContainer>
              <SectionTitle>{title}</SectionTitle>
              {paragraphs.map((paragraph) => (
                <Description spaceBetween={spaceBetween}>{paragraph}</Description>
              ))}
            </SectionTextContainer>
          </SectionContainer>
        </Fade>
      );
    } else {
      return (
        <Fade>
          <SectionContainer>
            <SectionTextContainer>
              <SectionTitle>{title}</SectionTitle>
              {paragraphs.map((paragraph) => (
                <Description spaceBetween={spaceBetween}>{paragraph}</Description>
              ))}
            </SectionTextContainer>
            <SectionImageContainer>
              <img  width='100%' 
                src={url + imgURL}
                lazyLoad={true}
              ></img>
            </SectionImageContainer>
          </SectionContainer>
        </Fade>
      );
    }
  };

  renderSectionMobile = ({ title, imgURL, paragraphs, spaceBetween }) => {
    return (
      <SectionContainerMobile>
        <SectionImageContainer>
          <img width={"100%"} src={url + imgURL}></img>
        </SectionImageContainer>
        <SectionTextContainer>
          <SectionTitle>{title}</SectionTitle>
          {paragraphs.map((paragraph) => (
            <Description spaceBetween={spaceBetween}>{paragraph}</Description>
          ))}
        </SectionTextContainer>
      </SectionContainerMobile>
    );
  };

  renderSections = (arrayOfSections) =>
    arrayOfSections.map((section) => {
      if(section.type == "section"){
        return (
          <>
            <Mobile>{this.renderSectionMobile(section)}</Mobile>
            <LargeDevice>{this.renderSection(section)}</LargeDevice>
          </>
       
        );
      }
      else if(section.type == "imageGrid"){
        return (
          <Fade>
              <BlockCentered>
                <ProjectTitle>
                  {section.title}
                </ProjectTitle>
              </BlockCentered>
            <SectionContainer>
              <DoubleBlockImage>
                {section.imgURLS.map((imgUrl)=>
                  <BlockImage3>
                    <img  width='100%' src={url + imgUrl} />
                  </BlockImage3>)}
              </DoubleBlockImage>
            </SectionContainer>
          </Fade>
        );
      }
    });

  
  render() {
    return (
      <>
      <HeaderMargin/>
        <OurWorkshopPageContainer>
          {this.state.isLoad && (
            <>
              {this.renderSections(this.state.complementary.sections)}
              <Gap></Gap>
              <ContactUs />
            </>
          )}
        </OurWorkshopPageContainer>
      </>
    );
  }
}

export default WorkshopPage;
