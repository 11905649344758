import React from "react";
import ReactPlayer from "react-player";
import { Block, ProjectTitle, BlockSections, BlockCentered, HeaderMargin } from "./BaseComponents";
import ContactUs from "./ContactUs";
import { LargeDevice, Mobile } from "../components/ReactResponsiveComponents";
import Fade from "react-reveal/Fade";



const baseUrl = "https://vimeo.com/";

class ShowreelPage extends React.Component {
    constructor(props) {
      super(props);
    //   this.id = this.props.match.params.id;
    //   this.state = { project: {}, isLoad: false };
    }
  
    render() {
        console.log(this.props.url);
      return (
        <>
          <Fade>
            <Block>
                  <BlockCentered>
                      <ProjectTitle>{"SHOWREEL "+ this.props.year}</ProjectTitle>
                  </BlockCentered>
                  <BlockSections>
                      <BlockCentered>
                          <LargeDevice>
                              <ReactPlayer width="800px" height="450px" url={baseUrl + this.props.url} playing={true} loop={true} controls={true} />
                          </LargeDevice>
                          <Mobile>
                              <ReactPlayer width="700px" height="300px" url={baseUrl + this.props.url} playing={true} loop={true} controls={true} />
                          </Mobile>
                      </BlockCentered>
                  </BlockSections>
            </Block>
          </Fade>
          <ContactUs></ContactUs>
        </>
      );
    }
}
  
  
  export default ShowreelPage;