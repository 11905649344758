
let baseUrl = { contacts: "" , projects: "", services: "", workshop: ""};

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // baseUrl.projects =  `${process.env.PUBLIC_URL}/` + "images/project_database/";
    // baseUrl.services = `${process.env.PUBLIC_URL}/` + "images/";
    // baseUrl.contacts = `${process.env.PUBLIC_URL}/` + "images/oficina_contactos.jpg";
    // baseUrl.workshop =  `${process.env.PUBLIC_URL}/` + "images/oficina/";
    baseUrl.projects = "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/project_database";
    baseUrl.services = "https://burilworkshop.s3.eu-west-3.amazonaws.com/images";
    baseUrl.contacts = "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/oficina_contactos.jpg";
    baseUrl.workshop =  "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/oficina/";

} else {
    baseUrl.projects = "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/project_database";
    baseUrl.services = "https://burilworkshop.s3.eu-west-3.amazonaws.com/images";
    baseUrl.contacts = "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/oficina_contactos.jpg";
    baseUrl.workshop =  "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/oficina/";
 
}


export default baseUrl;