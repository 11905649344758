import { combineReducers } from "redux";
import sectionStateReducer from './sectionStateReducer';
import flashpostStateReducer from './flashpostStateReducer';
import IsLoadedReducer from './IsLoadedReducer';
import changeRentingPageCategoryReducer from './changeRentingPageCategoryReducer';
import fetchItemsReducer from './fetchItemsReducer';

export default combineReducers({
  section: sectionStateReducer,
  flashpost: flashpostStateReducer,
  isSiteLoaded: IsLoadedReducer,
  selectedRentingCategory: changeRentingPageCategoryReducer,
  currItems: fetchItemsReducer
});
