import React from "react";
import styled from "styled-components";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import ReactPlayer from "react-player";
import ImageSlider  from "./ImageSlider";
import { Link } from "react-router-dom";
import { Mobile, LargeDevice } from "../components/ReactResponsiveComponents";


import baseUrl from "../constants/baseUrl";

const url = baseUrl.projects;


export const HeaderMargin = styled.div`

  @media ${mediaQueries.extraSmallDevices} {
    width: 0px;
    height: 0px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 0px;
    height: 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 100%;
    height: 70px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 100%;
    height: 70px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 100%;
    height: 70px;
  }
`;

export const  Block = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  align-items: flex-start;
  font-family: Druk, sans-serif;
  user-select: none;
  @media ${mediaQueries.extraSmallDevices} {
    width: 90%;
    margin: 10px auto;
  }
  @media ${mediaQueries.smallDevices} {
    width: 90%;
    margin: 30px auto;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 90%;
    margin: 30px auto;
  }
  @media ${mediaQueries.largeDevices} {
    width: 65%;
    margin: 30px auto;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 50%;
    max-width: 1300px;
    margin: 50px auto;
  }
`;

export const  Title = styled.div`
  font-size: 17px;
  font-family: "DM Sans", sans-serif;
`;

export const  SubtitleDateContainer = styled.div`
  width: 100%;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 13px;
    flex-direction: column;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 13px;
    flex-direction: column;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 13px;
    flex-direction: row;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 15px;
    flex-direction: row;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 15px;
    flex-direction: row;
  }
`;

export const  WideOpenImage = styled.div`
  width: 100%;
  margin-top: 25px;
  border-radius: 10px;
`;

export const  Subtitle = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
  }
  @media ${mediaQueries.smallDevices} {
  }
  @media ${mediaQueries.mediumDevices} {
    border-top: 2px grey solid;
  }
  @media ${mediaQueries.largeDevices} {
    border-top: 2px grey solid;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    border-top: 2px grey solid;
  }
`;

export const  Date = styled.div`
  border-top: 2px grey solid;
`;

export const  ProjectTitle = styled.div`
  color: #171718;
  font-family: Druk, sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 1.4vw;
    margin-top: 50px;
    margin-bottom: 10px;
  }
`;

export const  ProjectSection = styled.div`
  color: #282828;
  margin-top: 3px;
  margin-bottom: 2px ;
  border-radius: 2px;
  /* text-align: center; */
  border-bottom: #282828 solid 3px;
  border-top:  #282828 solid 3px;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 18px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 22px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const  BlockParagraph = styled.div`
  font-family: "DM Sans", sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 18px;
    margin: 35px 0px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 18px;
    margin: 35px 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 18px;
    margin: 35px 0px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 20px;
    margin: 35px 0px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 20px;
    margin: 35px 0px;
  }
`;

export const  BlockQuote = styled.div`
  width: 80%;
  margin: 25px auto;
  font-family: "DM Sans", sans-serif;
  font-style: italic;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 16px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 16px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 18px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 18px;
  }
`;

export const  BlockSections = styled.div`
  width: 100%;
  @media ${mediaQueries.extraSmallDevices} {
    margin-bottom: 30px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-bottom: 50px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-bottom: 100px;
  }
  @media ${mediaQueries.largeDevices} {
    margin-bottom: 100px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-bottom: 100px;
  }
`;

export const  BlockImage = styled.div`
  width: 100%;
  padding: 1px 0px;
`;

export const  BlockSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const  BlockCentered = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const  BlockVideo = styled.div`
  width: 100%;
  background: #282828;
  display: flex;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 2px;
`;


export const  SubBlockVideo = styled.div`
  display: flex;
  @media ${mediaQueries.extraSmallDevices} {
    width: 90%;
  }
  @media ${mediaQueries.smallDevices} {
    width: 90%;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 45%;
  }
  @media ${mediaQueries.largeDevices} {
    width: 45%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 45%;
  }
`;

export const  SubBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #00373E;
  margin: 1px 0px;
  border-radius: 2px;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
    padding: 25px;
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
    padding: 25px;
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: row;
    width: 100%;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: row;
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const  SubBlockImage = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    width: 95%;
  }
  @media ${mediaQueries.smallDevices} {
    width: 95%;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 45%;
    padding: 50px 0px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 45%;
    padding: 50px 0px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 45%;
    padding: 50px 0px;
  }
`;

export const  SubBlockText = styled.div`
  width: 40%;
  color: white;
  font-family: 'DM Sans', sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 20px;
    font-size: 16px;
    width: 95%;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 20px;
    font-size: 16px;
    width: 95%;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 16px;
    width: 40%;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 18px;
    width: 40%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 22px;
    width: 40%;
  }
`;

export const  DoubleBlockImage = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1px;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
    width: 100%;

  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: row;
    width: 100%;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: row;
    width: 100%;

  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const  DoubleBlockImageBold = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgb(40, 40, 40);
  padding: 100px 20px;
  border-radius: 2px;
  margin-top: 1px;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: row;
    width: 100%;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: row;
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const  BlockImage2 = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
    padding: 1px 0px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
    padding: 1px 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 50%;
    padding: 0px 1px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 50%;
    padding: 0px 1px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 50%;
    padding: 0px 1px;
  }
`;

export const  BlockImage3 = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
    padding: 5px 0px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
    padding: 5px 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 50%;
    padding: 5px 5px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 33.333%;
    padding: 5px 5px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 33.3%;
    padding: 5px 5px;
  }
`;


export const  CreditsContainer= styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  font-family: "DM Sans", sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 10px;
    margin-top: 30px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 12px;
    margin-top: 30px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 16px;
    margin-top: 50px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 16px;
    margin-top: 50px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 16px;
    margin-top: 50px;
  }
`;

export const  CreditsTitle = styled.div`
  background: grey;
  color: #171718;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 2px;
  margin-top: 2px;
  margin-bottom: 1px;
`;

export const  CreditName= styled.div`
  display: flex;
  width: 100%;
  background: #171718;
  justify-content: space-between;
  color: white;
  margin-top: 1px;
  border-radius: 2px;
`;

export const  CreditText= styled.div`
  padding: 10px;
  color: white;
`;


export const  CreditOffset= styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100% ;
  padding-left: ${props => props.isAssociated ? '20px' : '0px'};
`;




export const renderSection = (section) => {
      if (section.type === "textLink") {
        return <BlockParagraph as={Link} to={section.link}>{section.content}</BlockParagraph>;
      }
      if (section.type === "projectSection") {
        return (<ProjectSection>{section.content}</ProjectSection>);
      }
      if (section.type === "paragraph") {
        return <BlockParagraph>{section.content}</BlockParagraph>;
      }
      if (section.type === "quote") {
        return <BlockQuote>{section.content}</BlockQuote>;
      }
      if (section.type === "doubleImage") {
        return (
          <DoubleBlockImage>
            <BlockImage2>
              <img width={"100%"} style={{borderRadius: "2px"}} src={url + section.url1} />
            </BlockImage2>
            <BlockImage2>
              <img width={"100%"} style={{borderRadius: "2px"}} src={url + section.url2} />
            </BlockImage2>
          </DoubleBlockImage>
        );
      }
      if (section.type === "doubleImageBold") {
        return (
          <DoubleBlockImageBold>
            <BlockImage2>
              <img width={"100%"} style={{borderRadius: "2px"}} src={url + section.url1} />
            </BlockImage2>
            <BlockImage2>
              <img width={"100%"}  style={{borderRadius: "2px"}} src={url + section.url2} />
            </BlockImage2>
          </DoubleBlockImageBold>
        );
      }
      if (section.type === "image") {
        return (
          <BlockImage>
            <img width={"100%"} style={{borderRadius: "2px"}} src={url + section.url} />
          </BlockImage>
        );
      }
      if (section.type === "imageSlider") {
        console.log('RENDER IMAGESLIDER')
        return (
          <BlockCentered>
            <ImageSlider data={section.data} isLoading={false}></ImageSlider>
          </BlockCentered>
        );
      }
      if (section.type === "embebedVideo") {
        return (
          <BlockVideo>
            <ReactPlayer style={{borderRadius: "2px"}} volume={0} loop={true} controls playing={true} width="800px" height="600px" url={section.url} />
          </BlockVideo>
        );
      }
      if (section.type === "embebedVideoLocal") {
        return (
          <BlockVideo>
            <LargeDevice>
              <ReactPlayer style={{borderRadius: "2px"}} volume={0} loop={true} playing={true} width="800px" height="600px" url={url + section.url} />
            </LargeDevice>
            <Mobile>
              <ReactPlayer style={{borderRadius: "2px"}} volume={0} loop={true} playing={true} width="400px" height="300px" url={url + section.url} />
            </Mobile>
          </BlockVideo>
        );
      }
      if (section.type === "doubleEmbebedVideo") {
        return (
          <BlockSpaceBetween>
            <SubBlockVideo>
              <ReactPlayer loop url={section.url1} />
            </SubBlockVideo>
            <SubBlockVideo>
              <ReactPlayer loop url={section.url2} />
            </SubBlockVideo>
          </BlockSpaceBetween>
        );
      }
      if (section.type === "paragraphRightImage") {
        return (
          <SubBlock>
            <SubBlockText>{section.content}</SubBlockText>
            <SubBlockImage>
              <img width={"100%"} style={{borderRadius: "2px"}} src={url + section.url} />
            </SubBlockImage>
          </SubBlock>
        );
      }
      if (section.type === "paragraphLeftImage") {
        return (
          <SubBlock>
            <SubBlockImage>
              <img width={"100%"} style={{borderRadius: "2px"}} src={url + section.url} />
            </SubBlockImage>
            <SubBlockText>{section.content}</SubBlockText>
          </SubBlock>
        );
      }
      if (section.type === "credits") {
        const associatedText = section.isAssociatedToLastCredit ? 'com ' : '';
        return (
          <CreditsContainer>
              <CreditsTitle>{"Ficha Técnica"}</CreditsTitle>
              {section.externalCredits.map((ec)=> {return(
                <>
                  <CreditName>
                    <CreditText>{ec.credit}</CreditText>
                    <CreditText>{ec.name}</CreditText>
                  </CreditName>
                </>)
              })}
              <CreditOffset isAssociated={section.isAssociatedToLastCredit}>
                <CreditsTitle>{ associatedText + "Equipa BW"}</CreditsTitle>
                  {section.internalCredits.map((ec)=> {return(
                    <>
                      <CreditName>
                        <CreditText>{ec.credit}</CreditText>
                        <CreditText>{ec.name}</CreditText>
                      </CreditName>
                    </>
                    )
                    
                  })}
              </CreditOffset>
          </CreditsContainer>
        );
      }
      return <div></div>; // default
  };