import React from 'react';

export default function Logo(props) {
  return (
    <svg width="208.23454mm" height="38.213512mm" viewBox="0 0 208.23454 38.213512" version="1.1" id="svg8" {...props}>
      <g  id="layer1" transform="translate(-0.9601119,-90.417053)">
        <g transform="matrix(0.26458333,0,0,0.26458333,0.9601119,90.415376)">
          <g  id="Camada_1-2">
            <path id="path16" d="m 110.3,50.53 h 19.4 v 54.2 h -19.4 v -5.94 c -4.48,6.18 -11.51,7.76 -16.85,7.76 A 26,26 0 0 1 73.93,97.94 27.36,27.36 0 0 1 66.17,78.05 c 0,-10.18 4,-17.33 8.37,-21.7 a 27.92,27.92 0 0 1 19.52,-7.64 21.41,21.41 0 0 1 16.24,7 z M 89.94,68.72 a 12.67,12.67 0 0 0 -3.88,8.85 12.51,12.51 0 0 0 3.75,8.85 13.63,13.63 0 0 0 8.73,3.64 12.4,12.4 0 0 0 8.73,-3.76 11.77,11.77 0 0 0 3.64,-8.73 12,12 0 0 0 -3.76,-8.85 12.28,12.28 0 0 0 -17.21,0 z" className="cls-1"  style={{fill: '#282828'}} />
            <path id="path18" d="m 154,50.53 12.85,28.62 13.7,-28.62 h 11.88 L 206.15,80 219.73,50.54 h 21.58 l -28.37,54.2 h -13.09 l -13.58,-29.84 -13,29.83 H 160.2 l -27.89,-54.2 z" className="cls-1"  style={{fill: '#282828'}} />
            <path id="path20" d="M 19.4,127.77 H 0 v -77.9 h 19.4 v 5.94 a 20.26,20.26 0 0 1 16.6,-7.76 27,27 0 0 1 19.28,7.88 29.37,29.37 0 0 1 8.24,21 29.33,29.33 0 0 1 -8.72,21.44 26,26 0 0 1 -18.55,7.51 23,23 0 0 1 -16.85,-7 z m 3.15,-59.59 a 13.23,13.23 0 0 0 -3.76,9 13.12,13.12 0 0 0 3.76,8.61 12.42,12.42 0 0 0 8.73,3.63 11.71,11.71 0 0 0 8.61,-3.63 12.86,12.86 0 0 0 3.76,-8.61 13.23,13.23 0 0 0 -3.76,-9 11.68,11.68 0 0 0 -8.61,-3.64 12.39,12.39 0 0 0 -8.73,3.64 z" className="cls-1"  style={{fill: '#282828'}} />
            <path id="path22" d="m 263.32,50.53 v 21.28 l 18.31,-19.52 h 26.31 l -25.58,25 27.64,29.2 H 283.08 L 263.32,84.3 v 22.19 h -19.4 v -56 z" className="cls-1"  style={{fill: '#282828'}} />
            <path id="path24" d="M 261.29,98.64 C 253.93,104.44 246,109.21 237,112 c -5.09,1.57 -10.1,1.57 -14.88,-1.33 a 6.88,6.88 0 0 1 -3.6,-7.6 c 0.7,-4.38 2.74,-8.14 4.93,-11.82 6.35,-10.34 14.49,-19.19 23.19,-27.42 a 286.15,286.15 0 0 1 62.73,-45.35 c 5.8,-3.05 11.83,-5.79 18.49,-6.89 a 16.14,16.14 0 0 1 11.28,2.43 7.48,7.48 0 0 1 3.6,7.91 c -0.63,4.62 -2.43,8.85 -4.39,13 -6.26,13.79 -13.86,26.95 -21.3,40.1 -6.27,11.2 -12.77,22.33 -18.64,33.76 a 5,5 0 0 0 -0.86,2.27 c 0.7,-0.62 1.17,-1 1.64,-1.49 q 22.1,-22 45.27,-42.68 a 7.15,7.15 0 0 1 8.62,-1.18 c 2.58,1.26 3.29,2.51 2.82,5.41 -1,6.18 -2.82,12.14 -4.39,18.17 -2.51,9.16 -4.85,18.4 -5.95,28 0.86,-0.24 1.17,-0.94 1.56,-1.49 9.48,-11.9 18.8,-23.89 29.06,-35.17 16,-17.54 33.45,-33.52 51.3,-49.18 9.95,-8.78 20,-17.39 30.71,-25.22 A 61,61 0 0 1 467.28,0.5 3.71,3.71 0 0 1 470,0.11 c 1.1,0.31 2.35,0.55 2.59,1.72 0.24,1.17 -1,1.33 -1.65,1.88 -12.61,9.63 -24.35,20.21 -36,30.86 C 416.29,51.49 398.28,69 382,88.22 c -8.85,10.42 -16.84,21.46 -25.14,32.19 a 72.58,72.58 0 0 1 -7.86,9.09 9,9 0 0 1 -12.92,-0.79 5.27,5.27 0 0 1 -1.17,-3.13 c -0.79,-6.89 0.31,-13.55 1.72,-20.21 1.72,-8.45 4.39,-16.76 6.89,-25 a 4.26,4.26 0 0 0 0.47,-2 4.88,4.88 0 0 0 -1.88,1.33 648.55,648.55 0 0 0 -48.48,49.9 c -0.94,1 -1.88,2 -2.9,3 -3.44,3.21 -10.18,2.51 -12.92,-1.33 -1.1,-1.49 -0.78,-3.13 -0.47,-4.7 1.41,-6.11 4.15,-11.59 6.89,-17.15 7.83,-16 17.23,-31 25.85,-46.53 6.5,-11.67 13.08,-23.34 18.09,-35.79 a 30.29,30.29 0 0 0 2.19,-7.6 49,49 0 0 0 -7.12,3 c -19.35,9.4 -36.89,21.54 -53.5,35.17 -11.51,9.47 -22.16,19.73 -31.09,31.72 a 58.38,58.38 0 0 0 -7.44,12.68 c -0.47,1.1 -0.79,2.28 -1.18,3.45 -0.16,0.63 0.08,0.86 0.78,0.71 a 16.84,16.84 0 0 0 2,-0.55 c 6.5,-1.88 12.45,-5 18.41,-8.15 a 40,40 0 0 1 7.12,-3.29 c 2.59,-0.86 5.57,-0.08 6.9,1.8 0.31,0.4 0.86,0.87 0.54,1.41 a 1.06,1.06 0 0 1 -1.48,0.32 c -1.3,-0.62 -2.15,0.16 -3.01,0.87 z" className="cls-2"  style={{fill: '#193942'}} />
            <path id="path26" d="m 420,97.46 c -0.71,1.34 -1.26,2.67 -1.88,4.08 a 38.69,38.69 0 0 0 6.88,-5.33 125,125 0 0 0 11.44,-12.45 18.5,18.5 0 0 0 3.13,-5.88 c 0.86,-3.36 -0.16,-5.95 -3.29,-7.59 -1.33,-0.79 -2.9,-1.26 -3.84,-2.59 -0.39,-0.63 -0.94,-1.25 -0.47,-2 0.47,-0.75 1.25,-0.62 2,-0.47 5.48,1.65 10,4.55 12.45,10 a 8.83,8.83 0 0 1 -0.15,7.44 20.52,20.52 0 0 1 -2.59,4.15 221.28,221.28 0 0 1 -20.52,22.33 13.71,13.71 0 0 1 -1.1,0.94 c -2.42,2 -4.85,3.75 -8.14,2.11 -3.68,-1.8 -6.42,-4.39 -6.82,-8.77 a 24.91,24.91 0 0 1 2.2,-11.44 71.16,71.16 0 0 1 14,-21.3 c 2.11,-2.35 3.44,-2.43 6.18,-0.63 a 10.19,10.19 0 0 1 2,1.73 c 1.72,1.8 1.88,2.74 0.55,4.77 -4.16,6.81 -8.39,13.7 -12.03,20.9 z" className="cls-2"  style={{fill: '#193942'}} />
            <path id="path28" d="m 490.21,79.37 c 0.08,1 -1.17,1.1 -1.88,1.41 a 7.57,7.57 0 0 1 -6.5,-0.47 c -2.35,-1.25 -4.31,-0.7 -6.34,0.55 -4.23,2.58 -7.6,6.11 -11,9.71 a 105.91,105.91 0 0 0 -11.83,15.2 2.88,2.88 0 0 1 -3.83,1.17 c -4.23,-1.57 -5.57,-4.23 -4,-8.38 3.13,-8.38 8,-15.82 13.08,-23.18 a 39.41,39.41 0 0 1 4.62,-5.56 c 1.73,-1.73 3.53,-1.57 6.11,0.39 2.12,1.56 2.43,2.74 1.25,4.93 -0.54,1 -1.09,2.12 -1.95,3.6 1.41,-0.86 2.35,-1.48 3.29,-2 a 7.84,7.84 0 0 1 8.61,-0.32 9.42,9.42 0 0 0 7,1.18 2.55,2.55 0 0 1 1.1,0 c 1.06,0.2 2.11,0.75 2.27,1.77 z" className="cls-2"  style={{fill: '#193942'}} />
            <path id="path30" d="m 527.33,98.17 a 112.48,112.48 0 0 1 -23.89,4.23 22.46,22.46 0 0 1 -7.59,-0.63 c -0.87,-0.23 -1.26,0 -1.65,0.86 -2.82,6.82 -5.32,13.79 -7.52,20.84 a 2,2 0 0 0 0.24,1.72 1.19,1.19 0 0 1 0,1.8 c -0.94,1.1 -1.1,3.13 -3.37,2.67 -2.27,-0.46 -4.47,-2.67 -4.7,-4.78 a 27.94,27.94 0 0 1 0.63,-7.21 96.51,96.51 0 0 1 9,-26.55 q 11.4,-23.61 24,-46.68 a 44.83,44.83 0 0 1 5.48,-8.3 c 1.49,-1.73 2.59,-1.88 4.39,-0.79 2.19,1.34 2.82,2.51 1.88,4.62 -3.29,7.68 -7.21,15.12 -10.89,22.72 -2.58,5.25 -5.17,10.49 -7.91,15.9 a 2.43,2.43 0 0 0 1.57,-0.71 78.51,78.51 0 0 1 9.55,-6.42 7.56,7.56 0 0 1 7.29,0.08 c 1.64,0.78 1.72,1.64 0.31,2.74 -4.57,3.72 -9.15,7.28 -13.66,10.89 a 99.88,99.88 0 0 0 -8.69,7.44 18,18 0 0 0 -3.13,4.15 c 4.7,-0.39 8.92,-0.63 13.08,-1.1 4.7,-0.47 9.39,-0.78 14.09,-1.72 a 5,5 0 0 1 4.39,1.1 c 0.86,0.62 0.94,1.33 -0.08,1.8 a 18.15,18.15 0 0 1 -2.82,1.33 z" className="cls-2"  style={{fill: '#193942'}} />
            <path id="path32" d="m 640,54.7 c -1.95,1.8 -3.91,3.6 -5.79,5.48 a 14.89,14.89 0 0 0 -3,3.92 c -0.47,0.86 -0.47,2.27 -2.11,1.88 -1.64,-0.39 -3,-1.1 -3.37,-2.82 a 6.54,6.54 0 0 1 0.63,-4.07 33.83,33.83 0 0 1 6.26,-8.7 c 4.62,-5.17 9.4,-10.26 14,-15.51 A 50.22,50.22 0 0 0 655,22.66 c 0.55,-1 1.41,-2.34 0.79,-3.21 -0.63,-0.7 -2,0.16 -3.06,0.47 -15.59,4.7 -30.23,11.75 -44.17,20 A 252.3,252.3 0 0 0 565.47,71.7 91.66,91.66 0 0 0 554,84.38 a 13.38,13.38 0 0 0 -2.51,4.94 c -0.55,2.11 -0.24,2.5 1.88,2.74 6.11,0.63 12.22,-0.08 18.33,-0.63 13.7,-1.25 27.33,-2.74 41.12,-2 5.24,0.31 10.41,0.54 15.43,2.19 a 14.05,14.05 0 0 1 5.17,2.58 c 2.82,2 3.36,4.55 1.87,7.68 a 27,27 0 0 1 -4.69,6.42 c -5.25,6 -11.28,11.12 -16.92,16.76 -3.53,3.53 -6.89,7.13 -10.18,10.89 a 5.88,5.88 0 0 0 -0.63,0.86 c -1.41,2 -2.59,2.27 -4.86,1 -2.27,-1.27 -2.66,-2.43 -1.56,-4.86 2.35,-4.85 5.79,-8.85 9.16,-13 4.62,-5.87 9.79,-11.28 14.65,-16.92 a 31.84,31.84 0 0 0 4.15,-5.48 c 0.78,-1.25 0.78,-1.8 -0.94,-2 -4.54,-0.39 -9.09,-0.47 -12.93,-0.47 -11.74,0.24 -22.71,1.73 -33.67,2.82 -7.68,0.79 -15.36,1.57 -23,0.24 a 29.55,29.55 0 0 1 -10.81,-4 c -4.78,-3.21 -6,-7.36 -3.05,-12.37 3.13,-5.64 7.52,-10.26 12.21,-14.57 25.07,-22.87 53.19,-41 85.06,-52.87 a 55.29,55.29 0 0 1 12.85,-3.37 22.93,22.93 0 0 1 15,3.37 c 4.31,2.82 5.32,6.42 3,11.12 -1.72,3.61 -4.38,6.66 -7,9.64 C 654.6,42.17 647.16,48.28 640,54.7 Z" className="cls-2"  style={{fill: '#193942'}} />
            <path id="path34" d="m 680.05,86.89 a 3.94,3.94 0 0 0 0.31,-2.19 c -1.65,1.17 -3.29,2.27 -4.94,3.44 a 165.74,165.74 0 0 0 -23,20.84 l -0.47,0.47 c -3.14,3.29 -4.23,3.6 -7.13,1.64 -4.15,-2.82 -4.86,-5.32 -3.52,-8.69 2.42,-6.11 6,-11.59 9.24,-17.23 11.59,-19.58 24,-38.77 37.28,-57.26 a 40.89,40.89 0 0 1 3.68,-4.46 c 1.57,-1.72 2.66,-1.65 4.78,-0.08 2.12,1.57 2.58,2.82 1.41,4.78 -7,12.14 -14.8,23.73 -21.93,35.79 -5.09,8.46 -10.11,16.92 -15.12,25.46 4.86,-3.21 9.64,-6.43 14.49,-9.64 1.33,-0.86 2.66,-1.64 4,-2.5 1.88,-1.26 3.76,-1.88 5.79,-0.55 2.2,1.49 3.92,3.21 3.92,6.11 A 19.76,19.76 0 0 1 688,88 c -0.87,3.44 -2,6.73 -2.51,10.26 -0.39,2.19 -0.39,2.27 1.72,1.72 7.44,-1.8 14.26,-5.09 21.15,-8.3 1.88,-0.86 3.21,-0.55 4.31,1.17 a 1.19,1.19 0 0 1 -0.24,2 108.23,108.23 0 0 1 -21.38,11.12 c -7.05,2.74 -13.47,-4.07 -13,-9.87 a 40.44,40.44 0 0 1 2,-9.21 z" className="cls-2"  style={{fill: '#193942'}} />
            <path id="path36" d="m 719.59,97.46 c -0.7,1.34 -1.25,2.67 -1.88,4.08 a 38.77,38.77 0 0 0 6.82,-5.33 123.75,123.75 0 0 0 11.47,-12.45 18.33,18.33 0 0 0 3.14,-5.88 c 0.86,-3.36 -0.16,-5.95 -3.29,-7.59 -1.33,-0.79 -2.9,-1.26 -3.84,-2.59 -0.39,-0.63 -0.94,-1.25 -0.47,-2 0.47,-0.75 1.25,-0.62 2,-0.47 5.48,1.65 10,4.55 12.45,10 a 8.8,8.8 0 0 1 -0.16,7.44 20,20 0 0 1 -2.58,4.15 221.28,221.28 0 0 1 -20.52,22.33 c -0.31,0.31 -0.71,0.62 -1.1,0.94 -2.43,2 -4.85,3.75 -8.14,2.11 -3.68,-1.8 -6.43,-4.39 -6.82,-8.77 a 24.91,24.91 0 0 1 2.2,-11.44 71.16,71.16 0 0 1 14,-21.3 c 2.11,-2.35 3.44,-2.43 6.18,-0.63 a 10.19,10.19 0 0 1 2,1.73 c 1.72,1.8 1.88,2.74 0.55,4.77 -4.17,6.81 -8.4,13.7 -12.01,20.9 z" className="cls-2"  style={{fill: '#193942'}} />
            <path id="path38" d="m 731.57,138.58 c -0.62,1.65 -1.48,3.21 -1.25,5.09 0.08,1 -0.78,0.79 -1.33,0.63 a 4.68,4.68 0 0 1 -3.21,-4.7 26.08,26.08 0 0 1 2,-7.91 c 5.24,-14.41 12.29,-27.8 20.13,-41 4.62,-7.83 9.16,-15.82 14.25,-23.42 1.1,-1.57 2.27,-3 3.37,-4.54 a 2,2 0 0 1 2.66,-0.47 c 2.66,1.17 3.29,2.89 2,5.56 -0.16,0.31 -0.32,0.62 -0.47,0.94 2.35,-1.1 4.54,-2.59 6.74,-3.76 2.89,-1.57 5.32,-0.94 7.75,0.94 2.43,1.88 3.37,4.23 2.5,7.28 -1.4,5.72 -4.22,10.89 -7.2,15.9 a 144.89,144.89 0 0 1 -10.81,17.23 l -0.23,0.24 c -1.81,2.11 -3.61,4.85 -7,4.23 a 10.65,10.65 0 0 1 -8.07,-7.13 19.32,19.32 0 0 1 -1,-3.84 2.45,2.45 0 0 0 -0.47,0.55 c -7.35,12.42 -14.72,24.87 -20.36,38.18 z m 29.22,-41.27 a 12,12 0 0 0 0.47,3.44 c 6.73,-8.85 12.53,-17.93 16.6,-28.27 a 6.45,6.45 0 0 0 -0.94,0.31 c -4.54,2.9 -9.24,5.17 -13,9.24 a 11.3,11.3 0 0 0 -3.21,6.9 42.67,42.67 0 0 0 0.08,8.38 z" className="cls-2"  style={{fill: '#193942'}} />
          </g>
        </g>
      </g>
    </svg>
  );
}
