import Responsive from 'react-responsive';
import breakpoints from '../constants/breakpoints';
import React from 'react';

const SuperDesktop = props => <Responsive {...props} minWidth={breakpoints.extraLargeDevices} />;
const NotSuperDesktop = props => <Responsive {...props} maxWidth={breakpoints.extraLargeDevices-1} />


const Desktop = props => <Responsive {...props} minWidth={breakpoints.largeDevices} maxWidth={breakpoints.extraLargeDevices-1}/>;
const Tablet = props => <Responsive {...props} minWidth={breakpoints.mediumDevices} maxWidth={breakpoints.largeDevices-1} />;
const LargePhone = props => <Responsive {...props} minWidth={breakpoints.smallDevices} maxWidth={breakpoints.mediumDevices-1} />;
const SmallPhone = props => <Responsive {...props} maxWidth={breakpoints.smallDevices-1} />;

const LaptopDesktop = props => <Responsive {...props} minWidth={breakpoints.largeDevices}/>;

const Default = props => <Responsive {...props} minWidth={breakpoints.smallDevices-1} />;

const LargeDevice = props => <Responsive {...props} minWidth={breakpoints.mediumDevices} />;
const Mobile = props => <Responsive {...props} maxWidth={breakpoints.mediumDevices-1} />




export {Desktop, Tablet, LargePhone, SmallPhone, Mobile, Default, LargeDevice, SuperDesktop, NotSuperDesktop, LaptopDesktop};