

const sectionStateReducer = (section = null, action) => {
  if (action.type === "SECTION_CHANGE") {
    return action.payload;
  }
  return section;
};

export default sectionStateReducer;
