import {keyframes} from 'styled-components';
import { headShake } from 'react-animations';

const pulseFade = keyframes`
    0%   {opacity: 0;}
    50%  {opacity: 1;}
    100% {opacity: 0;}
`;

const fadeIn = keyframes`
    0%   {opacity: 0;}
    100%  {opacity: 1;}
`;

const zoomOutIn = keyframes`
    0%   {transform: scale(1);}
    50%  {transform: scale(1.2);}
    100%  {transform: scale(1);}
`;

const bounceAnimation = keyframes`${headShake}`;

export {pulseFade, bounceAnimation, fadeIn, zoomOutIn};