import React from "react";
import styled from "styled-components";
import MenuIcon from "./icons/Equal";
import { bounceAnimation } from "../animations";
import MobileNavigationMenu from "./MobileNavMenu";
import "../css/MobileNavigationMenuTransition.css";
import Logo from "./LogoBurilMobile.js";
import Slider from "./NewsSliderWidget";
import mediaQueries from "../constants/mediaQueries";
import { withRouter } from "react-router-dom";
import services from "../assets/content/NavBarDataMobile";

// Style

const NavBarContainer = styled.div`
  position: fixed;
  background: white;
  width: 100%;
  border: black solid 0px;
  border-bottom-width: 1px;
  z-index: 998;
  @media ${mediaQueries.extraSmallDevices} {
    height: 45px;
  }
  @media ${mediaQueries.smallDevices} {
    height: 60px;
  }
`;

const Gap = styled.div`
  width: 100%;
  @media ${mediaQueries.extraSmallDevices} {
    height: 45px;
  }
  @media ${mediaQueries.smallDevices} {
    height: 60px;
  }
`;

const LogoWrapper = styled.div`
  float: left;
  margin-left: 12px;
  margin-right: 30px;
  margin-top: 10px;
  animation: 1s
    ${(props) => (props.animationActive ? bounceAnimation : undefined)};
  @media ${mediaQueries.extraSmallDevices} {
    width: 150px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 200px;
  }
`;

const MenuButton = styled.div`
  float: right;

  @media ${mediaQueries.extraSmallDevices} {
    height: 45px;
    margin-right: 20px;
    padding: 12px 20px;
  }
  @media ${mediaQueries.smallDevices} {
    height: 60px;
    margin-right: 20px;
    padding: 15px 20px;
  }
`;

const SlideContainer = styled.div`
  overflow: hidden;
  border-right: solid black 1px;
  background: black;
  color: white;
  font-family: "Druk", sans-serif;
  margin-top: 2px;
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
    font-size: 9px;
    height: 30px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
    font-size: 9px;
    height: 30px;
  }
`;

const Container = styled.div`
  width: 100%;
`;

// Component
class MobileNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { navMenuIsOpen: false };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onClickMenuButton = () => {
    let value;
    value = this.state.navMenuIsOpen? false : true;
    this.setState({ navMenuIsOpen: value });
  };

  onFinalInteraction = () => {
    // Final Interaction With Menu
    this.setState({ navMenuIsOpen: false });
  };

  onClickLogo = () => {
    this.props.history.push('/');
    this.setState({ navMenuIsOpen: false });
  }

  render() {
    return (
      <Container>
        <NavBarContainer>
          <LogoWrapper animationActive={this.state.navMenuIsOpen}>
            <Logo
              onClick={this.onClickLogo}
              width={"100%"}
              height={"54%"}
            />
          </LogoWrapper>
      
            <MenuButton onClick={this.onClickMenuButton}>
              <MenuIcon height={`100%`} />
            </MenuButton>
        </NavBarContainer>
        <Gap />
        {/* <SlideContainer>
          <Slider />
        </SlideContainer> */}

        {/* <TransitionGroup component={null}>
            <CSSTransition
              in={this.state.navMenuIsOpen}
              timeout={1000}
              classNames={"mobile-navigation-menu"}
            >
              <MobileNavigationMenu
                sections={services}
                active={this.state.navMenuIsOpen}
                buttonClickHandler={this.onFinalInteraction}
              />
            </CSSTransition>
          </TransitionGroup> */}
        <MobileNavigationMenu
          sections={services}
          active={this.state.navMenuIsOpen}
          buttonClickHandler={this.onFinalInteraction}
        />
      </Container>
    );
  }
}

export default withRouter(MobileNavBar);
