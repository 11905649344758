import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div``;

const Image = styled.div`
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  margin-top: 10px;
  font-family: "DM Sans", sans-serif;
  color: black;
  font-size: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Price = styled.div`
  color: grey;
  font-size: 15px;
`;

const Reference = styled.div`
  margin-top: 10px;
  color: grey;
`;

const ProductCard = (props) => {
  const {
    id,
    name,
    reference,
    description,
    dimensions,
    photosUrls,
    fullprice,
    percentage
  } = props.data;
  const imageURL =
    `${process.env.PUBLIC_URL}/` + "./images/item_database/" + reference + "_1.jpg";
  let price = percentage*fullprice;
  return (
    <Container>
      <Content as={Link} to={`/aluguer/produto/${reference}`}>
        <Image>
          <img width={"100%"} src={imageURL} />
        </Image>
        <Header>{name}</Header>
        <Price>{ price.toFixed(1) + ` € / dia`}</Price>
      </Content>
      <Reference>
        <Icon name="clipboard" />
        {reference}
      </Reference>
    </Container>
  );
};

export default ProductCard;
