import React from "react";
import styled from "styled-components";
import InfoBlock from "./InfoBlock";
import InfoBlocksData from "../assets/content/InfoBlocksData";
import { connect } from "react-redux";
import breakpoints from "../constants/breakpoints";
import mediaQueries from "../constants/mediaQueries";
import LastestActivity from "./LatestActivity";
import ContactUs from "./ContactUs";
import "./LandingPage.css";
import { LargeDevice, Mobile } from "../components/ReactResponsiveComponents";
import axios from "axios";

const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  background: #363636;
  width: 100%;
  @media ${mediaQueries.extraSmallDevices} {
    /* height: calc(70vh); */
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    /* height: calc(70vh); */
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    top: calc(100vh);
  }
  @media ${mediaQueries.largeDevices} {
    height: calc(100vh);
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    height: calc(100vh);
  }
`;

const InfoBlockContainer = styled.div``;

class LandingPage extends React.Component {
  state = { isLoading: true, currentService: [], newsData:[], newsAreLoading: true };

  componentDidMount() {
    document.getElementById("video_i").play();
    axios.get(`/services/landing-page`).then((res) => {
      const service = res.data;
      this.setState({ currentService: service[0] });
      this.setState({ isLoading: false });
    });
    // axios.get(`/news`).then((res) => {
    //   const news = res.data;
    //   this.setState({ newsData: news });
    //   this.setState({ newsAreLoading: false });
    //   console.log(news);
    // });
  }

  invertDirection(direction) {
    if (direction === "left") {
      return "right";
    } else {
      return "left";
    }
  }

  renderInfoBlocks(services) {
    let direction = "right";
    return services.map((service) => {
      direction = this.invertDirection(direction);
      const { title, description, includes, button } = service;
      const imageURL =
        `${process.env.PUBLIC_URL}/` + "./images/" + service.imageName;
      return (
        <InfoBlock
          title={title}
          description={description}
          includes={includes}
          imageURL={imageURL}
          direction={direction}
          button={button}
          key={title}
        />
      );
    });
  }

  render() {
    const { title, description, includes, imageURL } = InfoBlocksData[0];

    return (
      <LandingPageContainer>
        <ImageContainer>
          <div className="player-wrapper">
            <Mobile>
              <video
                playsInline
                loop
                muted
                autoPlay
                id={"video_i"}
                width={"100%"}
              ><source src="https://burilworkshop.s3.eu-west-3.amazonaws.com/videos/MASTER_WEBSITE_SQUARE.mp4" /></video>
            </Mobile>
            <LargeDevice>
              <video
                id={"video_i"}
                height={"100%"}
                src="https://burilworkshop.s3.eu-west-3.amazonaws.com/videos/F_WEBSITE_WIDE.mp4"
                playsInline
                loop
                muted
                autoPlay
              />
            </LargeDevice>
          </div>
        </ImageContainer>
        <LastestActivity />
        {/* <News data={this.state.newsData} isLoading={this.state.newsAreLoading}/> */}
        {!this.state.isLoading && (
          <>
            <InfoBlockContainer>
              {this.renderInfoBlocks(this.state.currentService.subservices)}
            </InfoBlockContainer>
            <ContactUs />
          </>
        )}
      </LandingPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return { section: state.section };
};

export default connect(
  mapStateToProps,
  {}
)(LandingPage);
