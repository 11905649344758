import React from "react";
import styled from "styled-components";
import {
  changeRentingPageCategory,
  fetchItemsByKeyword,
} from "../actions/index";
import { connect } from "react-redux";
import { LargeDevice, Mobile } from "../components/ReactResponsiveComponents";

const placeholder = "nome, categoria, referência, ...";

class RentingPageSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // alert('A name was submitted: ' + this.state.value);
    this.props.changeRentingPageCategory("0");
    this.props.fetchItemsByKeyword(this.state.value);
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <LargeDevice>
          <input
            style={{
              fontFamily: "Druk, sans-serif",
              background: "white",
              margin: "0px",
            }}
            type="text"
            placeholder={placeholder}
            value={this.state.value}
            onChange={this.handleChange}
          />
        </LargeDevice>
        <Mobile>
          <input
            style={{
              fontFamily: "Druk, sans-serif",
              background: "white",
              margin: "0px",
              fontSize: "13px"
            }}
            type="text"
            placeholder={placeholder}
            value={this.state.value}
            onChange={this.handleChange}
          />
        </Mobile>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { activeCategory: state.selectedRentingCategory };
};

export default connect(
  mapStateToProps,
  { changeRentingPageCategory, fetchItemsByKeyword }
)(RentingPageSearchBar);
