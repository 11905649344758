export default [
  {
    title: "Design e Produção",
    sections: [
      { title: "Cenários", link: "/servicos/design-producao-cenarios" },
      { title: "Adereços", link: "/servicos/design-producao-aderecos" },
      { title: "Assistência de Arte", link: "/servicos/assistencia-arte"},
      { title: "SFX On-Set", link: "/servicos/efeitos-especiais-set" },
      { title: "Showrooms", link: "/servicos/showrooms-events"}
    ],
  },
  {
    title: "Multimédia",
    sections: [
      { title: "Instalações", link: "/servicos/multimedia/instalacoes", key: "1"},
      { title: "Interatividade", link: "/servicos/multimedia/interatividade", key: "2" },
      { title: "Comportamento Eletrónico", link: "/servicos/multimedia/comportamento-eletronico", key: "3" }
    ],
  },
  {
    title: "Aluguer",
    sections: [
      {
        title: "Adereços",
        link: "/servicos/servicos-tecnicos/aluguer-aderecos",
        key: "1",
      },
      {
        title: "Transportes",
        link: "/servicos/servicos-tecnicos/transportes",
        key: "2",
      }
    ],
  },
  {
    title: "Projetos",
    link: "/projetos",
    sections: [],
  },
  {
    title: "Workshop",
    sections: [
      { title: "Oficina", link: "/workshop/oficina" },
      { title: "Showreel 2023", link: "/showreel/2023/902208964"},
      { title: "Showreel 2022", link: "/showreel/2022/786048770"},
      { title: "Contactos", link: "/contacts" },
    ],
  },
];
