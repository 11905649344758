import React from "react";
import styled from "styled-components";
import mediaQueries from "../constants/mediaQueries";

const FIELDS = [
  "Armas e fatos",
  "Props de plástico, madeira e metal",
  "Design de ambientes",
  "Construção de cenários",
  "Impressão 3D e corte a laser",
  "Fabrico de roupas",
  "Frames para cenário"
];

const NUMBER_OF_FIELDS = 40;
const it = 0.5; // velocity in pixel per frame

const Slider = styled.div`
  @media ${mediaQueries.smallDevices} {
    height: 30px;
  }
  @media ${mediaQueries.mediumDevices} {
    height: 30px;
  }
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 5000px;
  height: 100%;
  border: #171718 solid 1px;
  background: #171718;
`;

const SliderField = styled.div`
  position: absolute;
  padding: 3px;
  @media ${mediaQueries.extraSmallDevices} {
    padding-left: 20px;
    padding-right: 60px;
  }
  @media ${mediaQueries.smallDevices} {
    padding-left: 20px;
    padding-right: 60px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding-left: 40px;
    padding-right: 60px;
  }
  @media ${mediaQueries.largeDevices} {
    padding-left: 40px;
    padding-right: 60px;
  }
  @media screen and (min-width: 1200px) {
    padding-left: 40px;
    padding-right: 60px;
  }
`;

class NewsSliderWidget extends React.Component {
  constructor(props) {
    super(props);
    this.sliderFields = [];
    for (var i = 0; i < NUMBER_OF_FIELDS; i++) {
      var fieldIndex = i % FIELDS.length;
      this.sliderFields[i] = <SliderField>{FIELDS[fieldIndex]}</SliderField>;
    }
    this.slide = React.createRef();
  }

  componentDidMount() {
    this.fieldsNodes = this.slide.current.childNodes;
    this.fields = [];
    var partialWidth = 0;
    this.fieldsNodes.forEach((node, i) => {
      node.style.transform = `translate(${partialWidth}px, 0px)`;
      this.fields[i] = { node, coordX: partialWidth };
      partialWidth += node.clientWidth;
    });
    this.fullWidth = partialWidth;
    this.lastCycleFieldIndex = 0;
    this.cycleLifeWidth = 0;
    this.requestFrame = window.requestAnimationFrame(this.updateFieldsPosition);
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.requestFrame);
  }

  updateFieldsPosition = () => {
    var lastCycleFieldWidth = this.fields[this.lastCycleFieldIndex].node
      .clientWidth;
    if (this.cycleLifeWidth >= lastCycleFieldWidth) {
      this.fields[
        this.lastCycleFieldIndex
      ].node.style.transform = `translate( ${this.fullWidth -
        lastCycleFieldWidth}px, 0px)`;
      this.fields[this.lastCycleFieldIndex].coordX =
        this.fullWidth - lastCycleFieldWidth;
      this.lastCycleFieldIndex =
        this.lastCycleFieldIndex + 1 === NUMBER_OF_FIELDS
          ? 0
          : this.lastCycleFieldIndex + 1;
      this.cycleLifeWidth = 0;
    }
    this.fields.forEach(field => {
      field.node.style.transform = `translate(${field.coordX - it}px,0px)`;
      field.coordX -= it;
    });
    this.cycleLifeWidth += it;
    window.requestAnimationFrame(this.updateFieldsPosition);
  };
  render() {
    return <Slider ref={this.slide}>{this.sliderFields}</Slider>;
  }
}

export default NewsSliderWidget;
