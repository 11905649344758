import React from "react";
import styled from "styled-components";
import ImageFader from "../utilsComponents/ImageFader";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import baseUrl from "../constants/baseUrl";

import {
  LargeDevice,
  Mobile,
  SuperDesktop,
  NotSuperDesktop,
  Tablet,
  LaptopDesktop,
} from "../components/ReactResponsiveComponents";

const url = baseUrl.services;

const styleImg = {
  width: "100%",
  height: "calc(100vh - 120px)",
  objectFit: "cover",
  filter: "brightness(70%)",
};

const styleImgTablet = {
  width: "100%",
  height: "calc(60vh)",
  objectFit: "cover",
  filter: "brightness(70%)",
};

const Background = styled.div`
  width: 100%;
  @media ${mediaQueries.extraSmallDevices} {
    height: calc(100vh - 120px);
  }
  @media ${mediaQueries.smallDevices} {
    height: calc(100vh - 120px);
  }
  @media ${mediaQueries.mediumDevices} {
    height: calc(60vh);
  }
  @media ${mediaQueries.largeDevices} {
    height: calc(100vh - 120px);
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    height: calc(100vh - 120px);
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 120px);
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 0px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 0px;
  }
`;

const InfoContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  color: white;
  font-size: 15px;
  background: 	rgba(0,55,62, 0.1);

  @media ${mediaQueries.extraSmallDevices} {
    height: calc(100vh - 120px);
  }
  @media ${mediaQueries.smallDevices} {
    height: calc(100vh - 120px);
  }
  @media ${mediaQueries.mediumDevices} {
    height: calc(60vh);
  }
  @media ${mediaQueries.largeDevices} {
    height: calc(100vh - 120px);
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    height: calc(100vh - 120px);
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  @media ${mediaQueries.extraSmallDevices} {
    width: 90%;
  }
  @media ${mediaQueries.smallDevices} {
    width: 90%;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 500px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 900px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 1000px;
  }
`;

const Title = styled.div`
  font-family: "Druk", sans-serif;
  font-weight: 400;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 20px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 23px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 26px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 35px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 38px;
  }
`;

const Description = styled.div`
  margin-top: 20px;
  font-family: "DM-Sans", sans-serif;
  font-weight: 400;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 16px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 17px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 16px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 18px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 22px;
  }
`;

const IconBox = styled.div`
  position: absolute;
  top: calc(100% - 90px);
  @media ${mediaQueries.extraSmallDevices} {
    padding-bottom: 20px;
    top: calc(100% - 50px);
  }
  @media ${mediaQueries.smallDevices} {
    padding-bottom: 30px;
    top: calc(100% - 40px);
  }
  @media ${mediaQueries.mediumDevices} {
    padding-bottom: 50px;
    top: calc(100% - 90px);
  }
  @media ${mediaQueries.largeDevices} {
    padding-bottom: 50px;
    top: calc(100% - 90px);
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding-bottom: 50px;
    top: calc(100% - 90px);
  }
`;

const Cover = ({ title, description, imagesArray }) => {
  // Remaping url photo names
  const photosUrls = imagesArray.map(
    (name) => url + name
  );

  return (
    <Background>
      <ImageContainer>
        <LaptopDesktop>
          <ImageFader
            content={photosUrls}
            animationDuration={1000}
            transitionDuration={4000}
            style={styleImg}
          />
        </LaptopDesktop>
        <Tablet>
          <ImageFader
            content={photosUrls}
            animationDuration={1000}
            transitionDuration={4000}
            style={styleImgTablet}
          />
        </Tablet>
        <Mobile>
        <ImageFader
            content={photosUrls}
            animationDuration={1000}
            transitionDuration={4000}
            style={styleImg}
          />
        </Mobile>
      </ImageContainer>
      <InfoContainer>
        <TextBox>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextBox>
        <Mobile>
        <IconBox>
          <i className={`arrow down icon large`}></i>
        </IconBox>
        </Mobile>
        <LargeDevice>
        <IconBox>
          <i className={`arrow down icon big`}></i>
        </IconBox>
        </LargeDevice>
      </InfoContainer>
    </Background>
  );
};

export default Cover;
